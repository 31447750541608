import { Dto } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

interface FetchOTCOrderProps {
  /**
   * OTC order id
   */
  id: string;
}

export interface FetchOtcOrderResponse {
  status: FetchOtcOrderStatus;
  data?: OTCOrderResource;
}

export enum FetchOtcOrderStatus {
  NotFound,
  OK,
  Error,
}

export interface OTCOrderResource {
  criteria: Array<{
    key: string;
    values: Array<string>;
  }>;
  baseAssetCategoryId: number;
  expiryUtc: string | null;
  id: string;
  isAuction: 0 | 1;
  isPrefunded: 0 | 1;
  placedBy: string | null;
  price: number;
  projectId: number | null;
  auctionRulesLink?: string;
  project?: {
    name: string;
    registryProjectId: string;
    __registry__?: {
      name: string;
    };
    vintages?: Record<string, number>;
    status: Dto.ProjectStatus;
  };
  projects?: Array<{
    registryProjectId: string;
  }>;

  qty: number;
  minQty: number | null;
  maxQty: number | null;
  quoteAsset: {
    name: string;
    symbol: string;
  };
  quoteAssetId: number;
  sideId: number;
  startAtUtc: number | null;
  statusId: number;
  statusReason: string | null;
  timeInForceTypeId: number;
  userId: number;
  qtyMultipleOf: number | null;
  initialQty: number;
  bestOffer?: number;
  bidVolume?: number;
}

export const fetchOTCOrder = async (props: FetchOTCOrderProps): Promise<FetchOtcOrderResponse> => {
  const userService = await userAuthenticatedApi();

  const res = userService.url(`/user/otc/order/${props.id}`).get();

  const resInfo = await res.res();

  if (resInfo.status === 404) {
    return {
      status: FetchOtcOrderStatus.NotFound,
    };
  }

  if (resInfo.status >= 300) {
    return {
      status: FetchOtcOrderStatus.Error,
    };
  }

  const resJson: {
    data: OTCOrderResource;
  } = await res.json();

  return {
    status: FetchOtcOrderStatus.OK,
    data: resJson.data as OTCOrderResource,
  };
};
