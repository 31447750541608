import { useEffect, useMemo } from 'react';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';

import { CMBOrderType, TabItem, Tabs, TabsSize, TabsVariant } from '@aircarbon/ui';
import { Const } from '@aircarbon/utils-common';

import { UI } from 'state/ui';
import { User } from 'state/user';

export const OrderTypeTabs = () => {
  const { staticFilters, setOrderType } = useFilters();
  const {
    status: { canViewAuction: checkCanViewAuction, canViewCmbAsk: checkCanViewCmbAsk },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();

  const isAuctionsV2Enabled = getSetting(Const.FeatureToggle.AuctionV2) === '1';

  const canViewAuction = checkCanViewAuction();
  const canViewCmbAsk = checkCanViewCmbAsk();

  const availableTabs = useMemo(() => {
    let tabs: Array<{
      label: string;
      id: string;
    }> = [
      canViewCmbAsk && {
        label: 'MB Bids',
        id: CMBOrderType.Bid,
      },
      canViewCmbAsk && {
        label: 'MB Offers',
        id: CMBOrderType.Offer,
      },
      isAuctionsV2Enabled &&
        canViewAuction && {
          label: 'Auctions',
          id: CMBOrderType.Auction,
        },
    ].filter((v) => !!v) as Array<{
      label: string;
      id: string;
    }>;

    if (tabs.length > 1) {
      tabs = [
        {
          label: 'All',
          id: 'all',
        },
        ...tabs,
      ];
    }

    return tabs;
  }, [canViewCmbAsk, canViewAuction]);

  const orderType = staticFilters.orderType;

  useEffect(() => {
    if (availableTabs?.find((tab) => tab.id === orderType)) {
      return;
    }

    setOrderType((availableTabs?.[0]?.id as CMBOrderType) ?? 'all');
  }, [orderType, availableTabs]);

  const onPressOrderTypeTab = (tab: TabItem) => {
    setOrderType(tab.id as CMBOrderType);
  };

  return (
    <Tabs
      activeItemId={staticFilters.orderType}
      variant={TabsVariant.Contained}
      size={TabsSize.m}
      items={availableTabs}
      onPressTab={onPressOrderTypeTab}
    />
  );
};
