import 'bootstrap-daterangepicker/daterangepicker.css';
import { useState } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import {
  type FilterDateRangeInput,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { Pagination, usePagination } from 'refreshed-component/molecules/Pagination';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { formatter, trades } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { User } from 'state/user';

import { formatDate } from 'utils/helpers';

import { getNonPaginatedTradeRequestsReport, useTradeRequestsReport } from './hooks/useTradeRquestsReport';

const { formatNumber } = formatter;

export const MarketBoardOrder = () => {
  const { accountUsers } = Account.useContainer();
  const accountOptions = accountUsers?.map((userAccount, index: number) => ({
    label: `${userAccount.first_name} ${userAccount.last_name}`,
    id: userAccount.user_id,
  }));

  const { status } = User.useContainer();

  const pagination = usePagination();

  const {
    selector: { getUserId, getUserRootId },
  } = User.useContainer();

  const filters: {
    account: FilterRadioBox;
    date: FilterDateRangeInput;
  } = {
    account: {
      type: 'radio-box',
      label: 'Accounts',
      list: accountOptions || [],
    },
    date: {
      type: 'date-range-input',
      label: 'Date range',
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});

  const userId = getUserId();
  const rootUserId = getUserRootId();

  const isMultiAccount = status.isCorporateMonitor() || status.isCorporateAdmin();

  const { tradeRequests, isLoading } = useTradeRequestsReport({
    page: pagination.page,
    limit: Number(pagination.pageSize),
    startDate: filterSelections?.date?.range?.startDate?.toISOString?.(),
    endDate: filterSelections?.date?.range?.endDate?.toISOString?.(),
    userId: filterSelections?.account?.selection?.toString() || (isMultiAccount ? rootUserId : userId),
  });

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageControls
            title="MB Orders"
            controls={{
              secondary: (
                <DownloadCSV
                  data={async () => {
                    const data = await getNonPaginatedTradeRequestsReport({
                      page: pagination.page,
                      limit: Number(pagination.pageSize),
                      startDate: filterSelections?.date?.range?.startDate?.toISOString?.(),
                      endDate: filterSelections?.date?.range?.endDate?.toISOString?.(),
                      userId:
                        filterSelections?.account?.selection?.toString() || (isMultiAccount ? rootUserId : userId),
                    });
                    return (
                      data?.data?.map((item) => {
                        return {
                          tradeId: item.id,
                          trader: `${item.trader.firstName} ${item.trader.lastName}`,
                          project: `${item.project.name}|${item.project.registryProjectId}`,
                          vintage: item.vintageYear,
                          registry: item.registry.name,
                          side: item.side === trades.OrderSide.Buy ? 'Buy' : 'Sell',
                          price: item.price,
                          quantity: formatNumber(item.quantity, 0),
                          date: formatDate(item.createdAt),
                        };
                      }) ?? []
                    );
                  }}
                  fileName={'mb-orders-report.csv'}
                />
              ),
            }}
          />
        </PageSections>
        <PageSections type="card" className="flex flex-col">
          <div className="flex flex-row justify-between items-center gap-base">
            <div className="flex flex-row gap-4 justify-start"></div>
            <div className="flex flex-row justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          {isLoading ? (
            <Loading isOverLay={true} />
          ) : (
            <Table
              config={{
                sticky: {
                  left: ['tradeId'],
                },
                columns: {
                  tradeId: {
                    label: 'TRADE ID',
                  },
                  trader: {
                    label: 'TRADER',
                  },
                  project: {
                    label: 'PROJECT',
                  },
                  vintage: {
                    label: 'VINTAGE',
                  },
                  registry: {
                    label: 'REGISTRY',
                  },
                  side: {
                    label: 'SIDE',
                  },
                  price: {
                    label: 'PRICE',
                  },
                  quantity: {
                    label: 'QUANTITY',
                  },
                  date: {
                    label: 'DATE',
                  },
                },
                rows:
                  tradeRequests?.data?.map((item) => {
                    return {
                      _key: String(item.id),
                      tradeId: item.id,
                      trader: `${item.trader.firstName} ${item.trader.lastName}`,
                      project: `${item.project.name}|${item.project.registryProjectId}`,
                      vintage: item.vintageYear,
                      registry: item.registry.name,
                      side:
                        item.side === trades.OrderSide.Buy ? (
                          <Text
                            color={Colors.success_700}
                            size={FontSize.small}
                            background={Colors.success_100}
                            spacing={Spacing.small}
                            spacingTB={Spacing._2xs}
                          >
                            Buy
                          </Text>
                        ) : (
                          <Text
                            color={Colors.danger_700}
                            size={FontSize.small}
                            background={Colors.danger_100}
                            spacing={Spacing.small}
                            spacingTB={Spacing._2xs}
                          >
                            Sell
                          </Text>
                        ),
                      price: item.price,
                      quantity: formatNumber(item.quantity, 0),
                      date: formatDate(item.createdAt),
                    };
                  }) || [],
              }}
            />
          )}
          <Pagination total={tradeRequests?.totalCount ?? 0} actions={pagination} />
        </PageSections>
      </PageHolder>
    </>
  );
};
