import Emittery from 'emittery';

import { OpenRequests } from '../../OpenRequests';
import { OrderHistory } from '../../OrderHistory';
import { TradeHistory } from '../../TradeHistory';

const eventEmitter = new Emittery();

const availableTabs = [
  {
    id: 'my-orders',
    label: 'My Orders',
    component: OrderHistory,
  },
  {
    id: 'my-requests',
    label: 'My Requests',
    component: OpenRequests,
  },
  {
    label: 'Trade History',
    id: 'trade-history',
    component: TradeHistory,
  },
];
let state = {
  activeTabId: availableTabs[0].id,
  isOpened: false,
};

export const activeTabStore = {
  availableTabs,
  getSnapshot() {
    return state;
  },
  subscribe(callback: () => void) {
    eventEmitter.on('update-state', (payload) => {
      state = payload;
      callback();
    });
  },
  updateState(
    callback: (prevState: { activeTabId: string; isOpened: boolean }) => { activeTabId: string; isOpened: boolean },
  ) {
    eventEmitter.emit('update-state', callback(state));
  },
};
