import { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Colors } from 'refreshed-component/design-system';

import { formatter } from '@aircarbon/utils-common';
import { Pair } from '@aircarbon/utils-common/src/dto';

import Loading from 'components/styled/Loading';

import SimpleBar from '../components/Simplebar';
import { CurrentPriceWrapper, CurrentPrice, Row, Cell, Bar } from './OrderBook';
import { PlaceOrderContext } from './PlaceOrderCarbon';

type RowData = {
  price: string;
  qty: string;
  total: string;
  _qty: string;
  _price: string;
};

type Props = {
  pair?: Pair;
  orderBook: any;
  isLoading?: boolean;
  link?: {
    label: string;
    url: string;
  };
  showCumulative?: boolean;
  theme?: 'gray';
  view?: 'LeftAndRight' | 'TopAndBottom';
  isPublic?: boolean;
};

function findDifferentPosition(a: string, b: string) {
  if (a.length < b.length) [a, b] = [b, a];
  return [...a].findIndex((chr, i) => chr !== b[i]);
}

function transformRows(
  orders: any[] = [],
  tokenNumDecimals: number,
  ccyNumDecimals: number,
  isSell: boolean,
): RowData[] {
  return orders.map(({ price, qty }: any) => ({
    price: Number(price) === 0 ? 'MKT' : formatter.formatNumber(price, ccyNumDecimals),
    qty: formatter.thousandFormatter(qty, 1),
    total: Number(price) === 0 ? 'MKT' : formatter.thousandFormatter(price * qty, 1),
    _qty: qty,
    _price: price,
  }));
}

const OrderBook = ({
  orderBook,
  link,
  isLoading: loading,
  view,
  showCumulative = false,
  theme,
  pair,
  isPublic,
}: Props) => {
  const rowHolder = useRef<HTMLDivElement | null>(null);
  const { setPlaceOrderData } = useContext(PlaceOrderContext);

  const tokenNumDecimals = pair?.baseAsset?.numDecimals ?? 0;
  const ccyNumDecimals = pair?.quoteAsset?.numDecimals ?? 2;

  const currentRow = {
    theme: 'gray',
    currentLastTradedPrice: orderBook?.lastTradedPrice || '',
  };
  let sellOrders = transformRows(
    (orderBook?.items ?? []).filter((item: any) => orderBook.sides[item.side_id] === 'Sell'),
    tokenNumDecimals,
    ccyNumDecimals,
    true,
  );
  let buyOrders = transformRows(
    (orderBook?.items ?? []).filter((item: any) => orderBook.sides[item.side_id] !== 'Sell'),
    tokenNumDecimals,
    ccyNumDecimals,
    false,
  );

  const sumOfSellQtyPrice = 100 / sellOrders.map((item) => parseFloat(item._qty)).reduce((a, b) => a + b, 0);
  const sumOfBuyQtyPrice = 100 / buyOrders.map((item) => parseFloat(item._qty)).reduce((a, b) => a + b, 0);

  function getStringDiffSplit(previousString: string, currentString: string, isLast: boolean) {
    if (!previousString || isLast) return currentString;
    const diffPosition = findDifferentPosition(previousString, currentString);
    if (diffPosition === -1) return <span className="opacity-70">{currentString}</span>;
    const [left, right] = [currentString.slice(0, diffPosition), currentString.slice(diffPosition)];
    return (
      <>
        <span className="opacity-70">{left}</span>
        {right}
      </>
    );
  }

  const isLeftAndRightView = view === 'LeftAndRight';

  const renderSellOrders = () => {
    let previousCumulativeQty: number = 0;
    let previousSell: string;

    if (sellOrders.length) {
      const rows = sellOrders.reverse().map((row, index, list) => {
        const qty = parseFloat(row._qty);
        const cumulativeQty = qty + previousCumulativeQty;
        const barValue = Math.ceil(sumOfSellQtyPrice * cumulativeQty);
        const isLast = list.length === index + 1;
        const decoratedPrice = getStringDiffSplit(previousSell || '', row.price, isLast);
        previousCumulativeQty = cumulativeQty;
        previousSell = row.price;
        return (
          <Row
            isPublic={isPublic}
            key={index.toString()}
            className="code"
            onClick={() =>
              !isPublic &&
              setPlaceOrderData?.({
                price: Number(row._price),
                quantity: Number(row._qty),
                side: 'Buy',
              })
            }
            theme={theme}
          >
            <Cell theme={'red'} className="flex-auto w-1/3">
              {decoratedPrice}
            </Cell>
            <Cell className={`w-1/3 flex-auto ${showCumulative ? 'text-center' : 'text-right'}`}>{row.qty}</Cell>
            {(!isLeftAndRightView || showCumulative) && (
              <Cell className="flex-auto w-1/3 text-right">{formatter.thousandFormatter(cumulativeQty, 1)}</Cell>
            )}
            <Bar theme={'red'} value={barValue} align={isLeftAndRightView ? 'left' : 'right'} />
          </Row>
        );
      });
      return isLeftAndRightView ? rows : rows.reverse();
    } else {
      return (
        <div
          style={{
            color: `var(${Colors.danger_700})`,
            fontSize: '11px',
          }}
          className="flex justify-center items-center w-full h-full text-sm"
        >
          No Sell Orders
        </div>
      );
    }
  };

  const renderBuyOrders = () => {
    let previousCumulativeQty: number = 0;
    let previousBuy: string;
    return buyOrders.length ? (
      buyOrders.map((row, index, list) => {
        const qty = parseFloat(row._qty);
        const cumulativeQty = qty + previousCumulativeQty;
        const barValue = Math.ceil(sumOfBuyQtyPrice * cumulativeQty);
        const isLast = list.length === index + 1;
        const decoratedPrice = getStringDiffSplit(previousBuy || '', row.price, isLast);
        previousCumulativeQty = cumulativeQty;
        previousBuy = row.price;
        return (
          <Row
            isPublic={isPublic}
            theme={theme}
            key={index.toString()}
            className="code"
            onClick={() =>
              !isPublic && setPlaceOrderData?.({ price: Number(row._price), quantity: Number(row._qty), side: 'Sell' })
            }
          >
            {isLeftAndRightView ? (
              <>
                {showCumulative && (
                  <Cell className="flex-auto w-1/3 text-left">{formatter.thousandFormatter(cumulativeQty, 1)}</Cell>
                )}
                <Cell className={`w-1/3 flex-auto ${showCumulative ? 'text-center' : 'text-left'}`}>{row.qty}</Cell>
                <Cell theme={'green'} className={`w-1/3 flex-auto text-right`}>
                  {decoratedPrice}
                </Cell>
              </>
            ) : (
              <>
                <Cell theme={'green'} className="flex-auto w-1/3">
                  {decoratedPrice}
                </Cell>
                <Cell className={`w-1/3 flex-auto ${isLeftAndRightView ? 'text-right' : 'text-center'}`}>
                  {row.qty}
                </Cell>
                <Cell className="flex-auto w-1/3 text-right">{formatter.thousandFormatter(cumulativeQty, 1)}</Cell>
              </>
            )}
            <Bar theme={'green'} value={barValue} />
          </Row>
        );
      })
    ) : (
      <div
        style={{
          color: `var(${Colors.success_700})`,
          fontSize: '11px',
          background: `var(${Colors.gray_0})`,
        }}
        className="flex justify-center items-center w-full h-full text-sm"
      >
        No Buy Orders
      </div>
    );
  };

  const drawTopAndBottom = () => {
    return (
      <div className="flex flex-col gap-3 w-full h-full">
        <div className="flex relative flex-row flex-1 gap-1 w-full">
          <div
            style={{
              position: 'absolute',
              left: '-10px',
              right: '-10px',
              top: 0,
              bottom: 0,
            }}
          >
            <SimpleBar className="w-full h-full">
              <div className="flex sticky top-0 z-10 flex-row gap-1 w-full min-h-full">
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} isHeader={true} theme={theme}>
                    <Cell theme={'light'} isInHeader={true} className="flex-auto w-1/3 font-semibold">
                      Price
                    </Cell>
                    <Cell theme={'light'} isInHeader={true} className="flex-auto w-1/3 font-semibold text-center">
                      Size
                    </Cell>
                    <Cell theme={'light'} isInHeader={true} className="flex-auto w-1/3 font-semibold text-right">
                      Cumulative
                    </Cell>
                  </Row>
                </div>
              </div>
              <div style={{ minHeight: '96px' }} className="flex relative flex-col flex-auto items-center mt-1 w-full">
                {loading ? (
                  <>
                    <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                      <Loading />
                    </div>
                  </>
                ) : (
                  <>
                    <div ref={rowHolder} className="flex flex-col flex-1 justify-end mb-1 w-full">
                      <div className="relative w-full">
                        <div className="w-full">{renderSellOrders()}</div>
                      </div>
                    </div>
                    <div className="flex flex-row flex-shrink gap-1 items-center w-full">
                      <CurrentPriceWrapper theme={currentRow.theme} className="flex w-full">
                        <div className={`${currentRow.currentLastTradedPrice ? '' : 'flex-1'}`}>
                          {currentRow.currentLastTradedPrice ? (
                            <>
                              <CurrentPrice className="flex flex-row flex-auto gap-2 justify-center items-center">
                                <div className="price code">
                                  {currentRow.currentLastTradedPrice || 'Empty Orderbook'}
                                </div>
                              </CurrentPrice>
                              {!!link && (
                                <Link
                                  to={link.url}
                                  style={{
                                    color: `var(${Colors.gray_900})`,
                                  }}
                                  className="cursor-pointer"
                                >
                                  {link.label}
                                </Link>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                color: `var(${Colors.gray_900})`,
                              }}
                              className="flex-1 w-full text-center"
                            >
                              Empty Orderbook
                            </div>
                          )}
                        </div>
                        {!!currentRow.currentLastTradedPrice && (
                          <div
                            style={{
                              color: `var(${Colors.gray_900})`,
                            }}
                            className="ml-"
                          >
                            Last Traded Price
                          </div>
                        )}
                      </CurrentPriceWrapper>
                    </div>
                    <div className="flex flex-col flex-1 items-start mt-1 w-full">
                      <div className="relative w-full">
                        <div className="w-full">{renderBuyOrders()}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  };

  const drawLeftAndRight = () => {
    return (
      <div className="flex flex-col gap-3 w-full h-full">
        <div className="flex relative flex-row flex-1 gap-1 w-full">
          <div
            style={{
              position: 'absolute',
              left: '-10px',
              right: '-10px',
              top: 0,
              bottom: 0,
            }}
          >
            <SimpleBar className="w-full h-full">
              <div
                className="flex sticky top-0 z-10 flex-row gap-1 w-full min-h-full"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} isHeader={true} theme={theme}>
                    {(!isLeftAndRightView || showCumulative) && (
                      <Cell theme={'light'} isInHeader={true} className="flex-auto w-1/3 font-semibold text-left">
                        Cumulative
                      </Cell>
                    )}
                    <Cell
                      theme={'light'}
                      isInHeader={true}
                      className={`w-1/3 flex-auto font-semibold ${showCumulative ? 'text-center' : 'text-left'}`}
                    >
                      Size
                    </Cell>
                    <Cell theme={'light'} isInHeader={true} className={`w-1/3 flex-auto font-semibold text-right`}>
                      Bid
                    </Cell>
                  </Row>
                </div>
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} isHeader={true} theme={theme}>
                    <Cell theme={'light'} isInHeader={true} className={`w-1/3 flex-auto font-semibold`}>
                      Offer
                    </Cell>
                    <Cell
                      theme={'light'}
                      isInHeader={true}
                      className={`w-1/3flex-auto font-semibold ${showCumulative ? 'text-center' : 'text-right'}`}
                    >
                      Size
                    </Cell>
                    {(!isLeftAndRightView || showCumulative) && (
                      <Cell theme={'light'} isInHeader={true} className="flex-auto w-1/3 font-semibold text-right">
                        Cumulative
                      </Cell>
                    )}
                  </Row>
                </div>
              </div>
              <div
                className="flex flex-row gap-1 w-full min-h-full"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <div className="flex flex-col flex-1">
                  <div className="flex relative flex-col flex-auto items-center mt-1 w-full">
                    {loading ? (
                      <>
                        <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                          <Loading />
                        </div>
                      </>
                    ) : (
                      <>
                        <div ref={rowHolder} className="flex flex-col flex-1 justify-start mb-2 w-full">
                          {renderBuyOrders()}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex relative flex-col flex-auto items-center mt-1 w-full">
                    {loading ? (
                      <>
                        <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                          <Loading />
                        </div>
                      </>
                    ) : (
                      <>
                        <div ref={rowHolder} className="flex flex-col flex-1 justify-start mb-2 w-full">
                          {renderSellOrders()}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  };

  return isLeftAndRightView ? drawLeftAndRight() : drawTopAndBottom();
};

export default OrderBook;
