import { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Table } from 'refreshed-component/templates/Table';
import { checkIfCurrentUserIsSeller } from 'refreshed-pages/market-board-v2/utils/checkIfCurrentUserIsSeller';
import { findLastCounterOffers } from 'refreshed-pages/market-board-v2/utils/findLastCounterOffers';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';
import { toOfferStatusBadgeProps } from 'refreshed-pages/market-board-v2/utils/toOfferStatusBadgeProps';

import {
  Badge,
  BadgeVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  CMBOrderType,
  IconName,
  InputTextSize,
  ListItem,
  Pagination,
  Select,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { assetCategoryUom, AssetCategory, trades } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { useEntity } from 'state/entity';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { useTradeRequests } from '../../hooks/useTradeRequests';
import { AcceptTradeRequestModal, AcceptTradeRequestModalRef } from '../AcceptTradeRequestModal';
import { CancelTradeRequestModal, CancelTradeRequestModalRef } from '../CancelTradeRequestModal';
import {
  CounterOfferModal,
  CounterOfferModalRef,
} from '../ListingPage/components/IncomingOffers/components/CounterOfferModal';
import {
  RejectRequestModal,
  RejectRequestModalRef,
} from '../ListingPage/components/IncomingOffers/components/RejectRequestModal';
import { toCounterOfferModalOffers } from '../ListingPage/components/IncomingOffers/utils/toCounterOfferModalOffers';
import { MoreActionsDropdown } from '../MoreActionsDropdown';
import { OpenRequest } from './utils/OpenRequest';
import { toOpenRequests } from './utils/toOpenRequests';

export const OpenRequests = () => {
  const {
    selector: { getUserId, getFullName },
    status: { canManageCmbBid },
  } = User.useContainer();

  const userId = getUserId();
  const userName = getFullName();
  const counterOfferModalRef = useRef<CounterOfferModalRef>(null);
  const rejectRequestModalRef = useRef<RejectRequestModalRef>(null);
  const acceptTradeRequestModalRef = useRef<AcceptTradeRequestModalRef>(null);
  const cancelTradeRequestModalRef = useRef<CancelTradeRequestModalRef>(null);
  const [filters, setFilters] = useState({
    requestStatus: 'all',
    currentPage: 1,
  });
  const {
    tradeRequests,
    isFetchingTradeRequests,
    showReviewOrderModal,
    showPlaceCounterOfferModal,
    onCounterOfferPlaced,
    onTradeRequestRejected,
    onTradeRequestCancelled,
    isAcceptingTradeRequest,
    showRejectTradeRequestModal,
    acceptTradeRequest,
    totalPages,
  } = useTradeRequests({
    counterOfferModalRef,
    rejectRequestModalRef,
    acceptTradeRequestModalRef,
    currentPage: filters.currentPage,
    requestStatus: filters.requestStatus !== 'all' ? [filters.requestStatus] : undefined,
  });
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();
  const { marketSettings } = useMarketSettings({});
  const currencyCode = selector.mainCcySymbol;
  const openRequests = useMemo(
    () => toOpenRequests({ tradeRequests, currentUserId: userId, currency: currencyCode }),
    [tradeRequests, userId, currencyCode],
  );

  const onPressCounterTradeRequest = (openRequest: OpenRequest) => {
    const orderType = toCMBOrderType({
      isAuction: !!openRequest.otcOrder.isAuction,
      sideId: openRequest.otcOrder.sideId,
    });

    showPlaceCounterOfferModal({
      id: openRequest.id,
      orderOpenQty: openRequest.orderOpenQty,
      orderType: openRequest.matchSide,
      baseAssetSymbol: openRequest.baseAsset?.symbol,
      reservedAssetQty: openRequest.reservedAssetQty ?? 0,
      sellerUserId: openRequest.sellerUserId,
      offers: toCounterOfferModalOffers({
        negotiations: openRequest.counterOffers,
        currentUserId: userId,
        currentUserName: userName,
        priceLabel: `Price (per ${uom})`,
        currency: currencyCode,
        orderType: openRequest.matchSide,
        isCurrentUserSeller: checkIfCurrentUserIsSeller({
          sellerUserId: openRequest.sellerUserId,
          currentUserId: userId,
          tradeRequestPlacedByUserId: openRequest.createdBy,
          otcOrderPlacedByUserId: openRequest.otcOrder.placedBy,
          otcOrderOwnerUserId: openRequest.otcOrder.userId,
          orderType,
        }),
      }),
    });
  };

  const onPressAcceptButton = (openRequest: OpenRequest) => {
    showReviewOrderModal({
      tradeRequestId: openRequest.id,
    });
  };

  return (
    <>
      {createPortal(
        <>
          <RejectRequestModal ref={rejectRequestModalRef} onRejected={onTradeRequestRejected} />
          <CounterOfferModal ref={counterOfferModalRef} onCounterOfferPlaced={onCounterOfferPlaced} />
          <AcceptTradeRequestModal
            ref={acceptTradeRequestModalRef}
            onConfirm={() => acceptTradeRequest()}
            isSubmitting={isAcceptingTradeRequest}
          />
          <CancelTradeRequestModal ref={cancelTradeRequestModalRef} onCancelled={onTradeRequestCancelled} />
        </>,
        document.body,
      )}
      <Filters>
        <FilterSection>
          <StatusFilterContainer>
            <Text variant={TypographyVariant.subtitle2}>Status</Text>
            <Select
              size={InputTextSize.s}
              items={[
                {
                  title: 'All',
                  value: 'all',
                },
                {
                  title: 'Opened',
                  value: trades.TradeRequestStatus.New,
                },
                {
                  title: 'Pending',
                  value: trades.TradeRequestStatus.Pending,
                },
                {
                  title: 'Accepted',
                  value: trades.TradeRequestStatus.Accepted,
                },
                {
                  title: 'Approved',
                  value: trades.TradeRequestStatus.Approved,
                },
                {
                  title: 'Processing',
                  value: trades.TradeRequestStatus.Processing,
                },
                {
                  title: 'Executed',
                  value: trades.TradeRequestStatus.Executed,
                },
                {
                  title: 'Cancelled',
                  value: trades.TradeRequestStatus.Cancelled,
                },
                {
                  title: 'Rejected',
                  value: trades.TradeRequestStatus.Rejected,
                },
              ]}
              value={filters.requestStatus}
              onChange={({ value }) => {
                setFilters({ currentPage: 1, requestStatus: value });
              }}
            />
          </StatusFilterContainer>
        </FilterSection>
      </Filters>
      <StyledTable
        config={{
          sticky: {
            left: ['id'],
            right: ['actions'],
          },
          columns: {
            id: {
              label: '#',
            },
            side: {
              label: 'SIDE / MATCH',
            },
            status: {
              label: 'STATUS',
            },
            name: {
              label: 'NAME / CRITERIA',
            },
            price: {
              label: 'PRICE',
            },
            quantity: {
              label: 'QTY',
            },
            actions: {
              label: 'ACTIONS',
            },
          },
          rows: openRequests.map((openRequest) => {
            const { receivedCounterOffersCount } = findLastCounterOffers({
              counterOffers: openRequest.counterOffers,
            });

            const actions = [
              canManageCmbBid() &&
                openRequest.lastPriceRaw > 0 &&
                (receivedCounterOffersCount > 1 || !!openRequest.otcOrder.isPrefunded) && {
                  label: 'Accept',
                  onPress: () => onPressAcceptButton(openRequest),
                  component: (
                    <Button
                      variant={ButtonVariant.secondary}
                      size={ButtonSize.xs}
                      onPress={() => onPressAcceptButton(openRequest)}
                      isDisabled={!marketSettings?.otcMatchingEnabled}
                    >
                      Accept
                    </Button>
                  ),
                },
              canManageCmbBid() &&
                openRequest.orderOpenQty > 0 && {
                  label: 'Counter',
                  component: (
                    <Button
                      variant={ButtonVariant.outlined}
                      size={ButtonSize.xs}
                      onPress={() => onPressCounterTradeRequest(openRequest)}
                      isDisabled={!marketSettings?.otcMatchingEnabled}
                    >
                      Counter
                    </Button>
                  ),
                  onPress: () => onPressCounterTradeRequest(openRequest),
                },

              canManageCmbBid() &&
                !openRequest.acceptedAt &&
                !openRequest.rejectedAt && {
                  label: 'Reject',
                  isDisabled: !marketSettings?.otcMatchingEnabled,
                  onPress: () => showRejectTradeRequestModal(openRequest.id),
                },
            ].filter((a) => !!a) as Array<{
              label: string;
              onPress: () => void;
              component?: JSX.Element;
              isDisabled?: boolean;
            }>;

            const mainActions = actions.length > 3 ? actions.slice(0, 2) : actions;
            const additionalActions = actions.length > 3 ? actions.slice(2, actions.length) : [];

            const shouldShowActionButtons = !openRequest.acceptedAt && !openRequest.rejectedAt;
            const shouldShowRejectionMessage =
              (openRequest.status === 'rejected' || openRequest.status === 'canceled') && openRequest.cancelledAt;

            return {
              _key: openRequest.id,
              id: openRequest.id,
              side: (
                <Badge
                  icon={IconName.Tag}
                  variant={
                    openRequest.side === CMBOrderType.Bid
                      ? BadgeVariant.Success
                      : openRequest.side === CMBOrderType.Auction
                        ? BadgeVariant.Alert
                        : BadgeVariant.Danger
                  }
                  value={`${openRequest.side === CMBOrderType.Offer ? 'Sell' : 'Buy'}${
                    openRequest.matchId
                      ? (openRequest.matchSide === CMBOrderType.Bid
                          ? ' / Bid #'
                          : openRequest.matchSide === CMBOrderType.Auction
                            ? ' / Auction #'
                            : ' / Offer #') + openRequest.matchId
                      : ''
                  }`}
                />
              ),
              status: <Badge {...toOfferStatusBadgeProps(openRequest.status)} />,
              name: openRequest.description ? (
                <StyledListItem isReadOnly title={openRequest.name} description={openRequest.description} />
              ) : (
                openRequest.name
              ),
              price: openRequest.price,
              quantity: openRequest.quantity,
              ...(openRequest.matchSide !== CMBOrderType.Auction
                ? {
                    actions: shouldShowActionButtons ? (
                      <ActionButtons>
                        {[
                          ...mainActions.map(
                            (action) =>
                              action.component || (
                                <Button
                                  key={action.label}
                                  onPress={action.onPress}
                                  variant={ButtonVariant.outlined}
                                  size={ButtonSize.xs}
                                  isDisabled={action.isDisabled}
                                >
                                  {action.label}
                                </Button>
                              ),
                          ),
                          !!additionalActions.length && (
                            <MoreActionsDropdown key={'moreActions'} actions={additionalActions} />
                          ),
                        ]}
                      </ActionButtons>
                    ) : shouldShowRejectionMessage ? (
                      <div>
                        <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                          Rejected on {openRequest.rejectedAt}
                        </Text>
                        {openRequest.rejectionReason && (
                          <Text variant={TypographyVariant.subtitle2}>{openRequest.rejectionReason}</Text>
                        )}
                      </div>
                    ) : (
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                        Accepted on {openRequest.acceptedAt}
                      </Text>
                    ),
                  }
                : {
                    actions:
                      (openRequest.status === 'rejected' ||
                        (openRequest.status === 'canceled' && openRequest.matchSide !== CMBOrderType.Auction)) &&
                      openRequest.cancelledAt ? (
                        <div>
                          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                            Rejected on {openRequest.rejectedAt}
                          </Text>
                          {openRequest.rejectionReason && (
                            <Text variant={TypographyVariant.subtitle2}>{openRequest.rejectionReason}</Text>
                          )}
                        </div>
                      ) : openRequest.cancelledAt ? (
                        <div>
                          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                            Cancelled on {openRequest.cancelledAt}
                          </Text>
                          {openRequest.rejectionReason && (
                            <Text variant={TypographyVariant.subtitle2}>{openRequest.rejectionReason}</Text>
                          )}
                        </div>
                      ) : openRequest.acceptedAt ? (
                        <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                          Accepted on {openRequest.acceptedAt}
                        </Text>
                      ) : (
                        false
                      ),
                  }),
            };
          }),
          loading: isFetchingTradeRequests,
        }}
      />
      <PaginationContainer>
        <Pagination
          currentPage={filters.currentPage}
          pagesCount={totalPages}
          onChange={(value) => {
            setFilters((prevFilters) => ({ ...prevFilters, currentPage: value }));
          }}
        />
      </PaginationContainer>
    </>
  );
};

const PaginationContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => toSpacing(theme)(12)};
  padding: 0 ${({ theme }) => toSpacing(theme)(12)};
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const StatusFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

const StyledTable = styled(Table)`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  align-items: center;
`;
