export const productPermissionLabel = {
  // webAccessEnabled: 'Web Access',
  spotViewEnabled: 'Spot: View',
  spotTradeEnabled: 'Spot: Trade',
  carbonFinderViewEnabled: 'CarbonFinder: View',
  carbonFinderTradeEnabled: 'CarbonFinder: Trade',
  cmbViewEnabled: 'CMB: View',
  cmbManageEnabled: 'CMB: Manage',
  cmbTradeEnabled: 'CMB: Trade',
  auctionViewEnabled: 'Auction: View',
  auctionTradeEnabled: 'Auction: Trade',
  blockTradeInViewEnabled: 'Block Trade In: View',
  blockTradeInManageEnabled: 'Block Trade In: Manage',
  blockTradeOutViewEnabled: 'Block Trade Out: View',
  blockTradeOutManageEnabled: 'Block Trade Out: Manage',
  blockTradeBrokerManageEnabled: 'Block Trade Broker: Manage',
  biofuelViewEnabled: 'Biofuel: View',
  biofuelTradeEnabled: 'Biofuel: Trade',
  externalClientViewEnabled: 'External Client: View',
  externalClientManageEnabled: 'External Client: Manage',
  reportsViewEnabled: 'Reports',
  fiatCustodyEnabled: 'Fiat Custody',
  carbonCustodyEnabled: 'Carbon Custody',
  gerCustodyEnabled: 'GER Custody',
  transferEnabled: 'Asset Management (Transfer)',
  userManagementEnabled: 'User Management',
  overViewEnabled: 'Overview',
  apxProjectManageEnabled: 'Project Exchange: Manage',
  apxTradeEnabled: 'Project Exchange: Trade',
  recTradeEnabled: 'REC: Trade',
  settleViewEnabled: 'Settlement: View',
  settleTradeEnabled: 'Settlement: Trade',
  settleBrokerEnabled: 'Settlement: Broker',
} as const;

export const productPermissionRole = {
  // webAccessEnabled: 'WEB_ACCOUNT_ACCESS',
  spotViewEnabled: 'WEB_ACCOUNT_SPOT_TRADE:VIEW',
  spotTradeEnabled: 'WEB_ACCOUNT_SPOT_TRADE:TRADE',
  carbonFinderViewEnabled: 'WEB_ACCOUNT_CARBON_FINDER:VIEW',
  carbonFinderTradeEnabled: 'WEB_ACCOUNT_CARBON_FINDER:TRADE',
  cmbViewEnabled: 'WEB_ACCOUNT_CMB:VIEW',
  cmbManageEnabled: 'WEB_ACCOUNT_CMB:MANAGE',
  cmbTradeEnabled: 'WEB_ACCOUNT_CMB:TRADE',
  auctionViewEnabled: 'WEB_ACCOUNT_AUCTION:VIEW',
  auctionTradeEnabled: 'WEB_ACCOUNT_AUCTION:TRADE',
  blockTradeInViewEnabled: 'WEB_ACCOUNT_BLOCK_IN:VIEW',
  blockTradeInManageEnabled: 'WEB_ACCOUNT_BLOCK_IN:MANAGE',
  blockTradeOutViewEnabled: 'WEB_ACCOUNT_BLOCK_OUT:VIEW',
  blockTradeOutManageEnabled: 'WEB_ACCOUNT_BLOCK_OUT:MANAGE',
  blockTradeBrokerManageEnabled: 'WEB_ACCOUNT_BROKER:MANAGE',
  biofuelViewEnabled: 'WEB_ACCOUNT_BIOFUEL_TRADE:VIEW',
  biofuelTradeEnabled: 'WEB_ACCOUNT_BIOFUEL_TRADE:TRADE',
  externalClientViewEnabled: 'WEB_ACCOUNT_EXTERNAL_CLIENT:VIEW',
  externalClientManageEnabled: 'WEB_ACCOUNT_EXTERNAL_CLIENT:MANAGE',
  reportsViewEnabled: 'WEB_ACCOUNT_REPORTS',
  fiatCustodyEnabled: 'WEB_ACCOUNT_FIAT_CUSTODY',
  carbonCustodyEnabled: 'WEB_ACCOUNT_CARBON_CUSTODY',
  gerCustodyEnabled: 'WEB_ACCOUNT_GER_CUSTODY',
  transferEnabled: 'WEB_ACCOUNT_TRANSFER',
  userManagementEnabled: 'WEB_ACCOUNT_USER_MANAGEMENT',
  overViewEnabled: 'WEB_ACCOUNT_OVERVIEW',
  apxProjectManageEnabled: 'WEB_ACCOUNT_APX_PROJECT:MANAGE',
  apxTradeEnabled: 'WEB_ACCOUNT_APX:TRADE',
  recTradeEnabled: 'WEB_ACCOUNT_REC',
  settleViewEnabled: 'WEB_ACCOUNT_SETTLE_VIEW',
  settleTradeEnabled: 'WEB_ACCOUNT_SETTLE_MANAGE',
  settleBrokerEnabled: 'WEB_ACCOUNT_SETTLE_BROKER',
};

export type ProductPermissionType = {
  // webAccessEnabled: 0 | 1;
  spotViewEnabled: 0 | 1;
  spotTradeEnabled: 0 | 1;
  carbonFinderViewEnabled: 0 | 1;
  carbonFinderTradeEnabled: 0 | 1;
  cmbViewEnabled: 0 | 1;
  cmbManageEnabled: 0 | 1;
  cmbTradeEnabled: 0 | 1;
  auctionViewEnabled: 0 | 1;
  auctionTradeEnabled: 0 | 1;
  blockTradeInViewEnabled: 0 | 1;
  blockTradeInManageEnabled: 0 | 1;
  blockTradeOutViewEnabled: 0 | 1;
  blockTradeOutManageEnabled: 0 | 1;
  blockTradeBrokerManageEnabled: 0 | 1;
  externalClientViewEnabled: 0 | 1;
  externalClientManageEnabled: 0 | 1;
  biofuelViewEnabled: 0 | 1;
  biofuelTradeEnabled: 0 | 1;
  reportsViewEnabled: 0 | 1;
  fiatCustodyEnabled: 0 | 1;
  carbonCustodyEnabled: 0 | 1;
  gerCustodyEnabled: 0 | 1;
  transferEnabled: 0 | 1;
  userManagementEnabled: 0 | 1;
  overViewEnabled: 0 | 1;
  apxProjectManageEnabled: 0 | 1;
  apxTradeEnabled: 0 | 1;
  recTradeEnabled: 0 | 1;
  settleViewEnabled: 0 | 1;
  settleTradeEnabled: 0 | 1;
  settleBrokerEnabled: 0 | 1;
};

export const ProductPermissionMoleculerProps = {
  // webAccessEnabled: { type: 'number', enum: [0, 1] , optional: true },
  spotViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  spotTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  carbonFinderViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  carbonFinderTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  cmbViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  cmbManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  cmbTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  auctionViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  auctionTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  blockTradeInViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  blockTradeInManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  blockTradeOutViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  blockTradeOutManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  blockTradeBrokerManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  biofuelViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  biofuelTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  externalClientViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  externalClientManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  reportsViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  fiatCustodyEnabled: { type: 'number', enum: [0, 1], optional: true },
  carbonCustodyEnabled: { type: 'number', enum: [0, 1], optional: true },
  gerCustodyEnabled: { type: 'number', enum: [0, 1], optional: true },
  transferEnabled: { type: 'number', enum: [0, 1], optional: true },
  userManagementEnabled: { type: 'number', enum: [0, 1], optional: true },
  overViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  apxProjectManageEnabled: { type: 'number', enum: [0, 1], optional: true },
  apxTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  recTradeEnbaled: { type: 'number', enum: [0, 1], optional: true },
  settleViewEnabled: { type: 'number', enum: [0, 1], optional: true },
  settleTradeEnabled: { type: 'number', enum: [0, 1], optional: true },
  settleBrokerEnabled: { type: 'number', enum: [0, 1], optional: true },
};

export const productPermissionDefaultRoles = {
  Corporate: {
    Trader: [
      'WEB_ACCOUNT_ACCESS',
      'WEB_ACCOUNT_SPOT_TRADE:TRADE',
      'WEB_ACCOUNT_CARBON_FINDER:TRADE',
      'WEB_ACCOUNT_CMB:MANAGE',
      'WEB_ACCOUNT_CMB:TRADE',
      'WEB_ACCOUNT_AUCTION:TRADE',
      'WEB_ACCOUNT_OBO:TRADE',
      'WEB_ACCOUNT_SETTLE_MANAGE',
    ],
    Monitor: ['WEB_ACCOUNT_ACCESS', 'WEB_ACCOUNT_REPORTS', 'WEB_ACCOUNT_OVERVIEW'],
    Client_DMA: [
      'WEB_ACCOUNT_ACCESS',
      'WEB_ACCOUNT_SPOT_TRADE:TRADE',
      'WEB_ACCOUNT_CARBON_FINDER:TRADE',
      'WEB_ACCOUNT_CMB:MANAGE',
      'WEB_ACCOUNT_CMB:TRADE',
      'WEB_ACCOUNT_AUCTION:TRADE',
      'WEB_ACCOUNT_REPORTS',
      'WEB_ACCOUNT_SETTLE_MANAGE',
    ],
    Client_Read_Only: [
      'WEB_ACCOUNT_ACCESS',
      'WEB_ACCOUNT_SPOT_TRADE:VIEW',
      'WEB_ACCOUNT_CARBON_FINDER:VIEW',
      'WEB_ACCOUNT_CMB:VIEW',
      'WEB_ACCOUNT_AUCTION:VIEW',
      'WEB_ACCOUNT_REPORTS',
      'WEB_ACCOUNT_SETTLE_VIEW',
    ],
    Admin: [
      'WEB_ACCOUNT_ACCESS',
      'WEB_ACCOUNT_SPOT_TRADE:VIEW',
      'WEB_ACCOUNT_CARBON_FINDER:VIEW',
      'WEB_ACCOUNT_CMB:VIEW',
      'WEB_ACCOUNT_AUCTION:VIEW',
      'WEB_ACCOUNT_REPORTS',
      'WEB_ACCOUNT_FIAT_CUSTODY',
      'WEB_ACCOUNT_CARBON_CUSTODY',
      'WEB_ACCOUNT_GER_CUSTODY',
      'WEB_ACCOUNT_TRANSFER',
      'WEB_ACCOUNT_USER_MANAGEMENT',
      'WEB_ACCOUNT_OBO:TRADE',
      'WEB_ACCOUNT_OVERVIEW',
      'WEB_ACCOUNT_SETTLE_VIEW',
    ],
  },
  Individual: [
    'WEB_ACCOUNT_ACCESS',
    'WEB_ACCOUNT_SPOT_TRADE:TRADE',
    'WEB_ACCOUNT_CARBON_FINDER:TRADE',
    'WEB_ACCOUNT_CMB:MANAGE',
    'WEB_ACCOUNT_CMB:TRADE',
    'WEB_ACCOUNT_AUCTION:TRADE',
    'WEB_ACCOUNT_REPORTS',
    'WEB_ACCOUNT_FIAT_CUSTODY',
    'WEB_ACCOUNT_CARBON_CUSTODY',
    'WEB_ACCOUNT_GER_CUSTODY',
    'WEB_ACCOUNT_TRANSFER',
    'WEB_ACCOUNT_USER_MANAGEMENT',
    'WEB_ACCOUNT_SETTLE_MANAGE',
  ],
  'external-broker': [
    'WEB_ACCOUNT_ACCESS',
    'WEB_ACCOUNT_BLOCK_IN:MANAGE',
    'WEB_ACCOUNT_BLOCK_OUT:MANAGE',
    'WEB_ACCOUNT_BROKER:MANAGE',
    'WEB_ACCOUNT_EXTERNAL_CLIENT:MANAGE',
    'WEB_ACCOUNT_SETTLE_BROKER',
  ],
};
