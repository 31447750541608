import { useState } from 'react';
import { Colors, FontSize } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Pair } from '@aircarbon/utils-common/src/dto';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import { Toggle } from '../components/Toogle';
import { AssetCategoryCode, Layouts } from '../layouts/trading.hook';
import OrderBookBiofuel from './OrderBookBiofuel';
import OrderBookL2 from './OrderBookL2';
import OrderBookL3 from './OrderBookL3';

type Theme = 'red' | 'green' | 'gray';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 5px;
`;

export const CurrentPriceWrapper = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: row;
  font-size: 11px;
  gap: 5px;
  background-color: ${(props) => (props.theme === `gray` ? `var(${Colors.gray_50})` : `var(${Colors.gray_0})`)};
  padding: 5px 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  transition:
    border 300ms,
    color 300ms;
  ${(props) => {
    if (props.theme === 'red') {
      return `color: var(${Colors.danger_700});`;
    } else if (props.theme === 'green') {
      return `color: var(${Colors.success_700});`;
    } else {
      return `color: var(${Colors.gray_900});`;
    }
  }};
`;

export const CurrentPrice = styled.div`
  font-size: 14px;
  color: var(${Colors.gray_900});
`;

export const Row = styled.div<{ theme?: Theme; isPublic?: boolean; isHeader?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 26px;
  line-height: 26px;
  font-size: var(${FontSize.small});
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  margin-bottom: 1px;
  ${(props) =>
    props.isHeader
      ? props.theme === 'gray'
        ? `background: var(${Colors.gray_50});`
        : `background: var(${Colors.gray_0});`
      : `
    background: ${props.theme === 'gray' ? `var(${Colors.gray_50})` : `var(${Colors.gray_0})`};
    ${
      !props.isPublic
        ? `&:hover {
      background-color: var(${Colors.gray_100});
      cursor: pointer;
    }`
        : ``
    }
  `}

  &.order-disabled {
    opacity: 0.5;
    background: transparent;
  }
`;

export const Bar = styled.div<{ theme?: Theme; value: number; align?: 'right' | 'left' }>`
  width: ${(props) => props.value}%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => (props.theme === 'green' ? `var(${Colors.buyLight})` : `var(${Colors.sellLight})`)};
  z-index: 1;
  //opacity: ${(props) => (props.theme === 'green' ? '0.15' : '0.11')};
  ${(props) => (props.align === 'left' ? 'left: 0;' : 'right: 0;')}
`;

const ViewsWrapper = styled.div<{ layout?: Layouts }>`
  ${(props) =>
    props.layout === 'mobile' || props.layout === 'tablet'
      ? ``
      : `position: absolute;
  top: -28px;
  left: 100px;
  right: 0;`}
  gap: 10px;

  button {
    border: 0.5px solid var(${Colors.gray_100});
    border-radius: 3px;
    padding: 4px;
    opacity: 0.7;

    &.selected {
      opacity: 1;
      background: var(${Colors.gray_200});
    }

    &.levelButton {
      color: var(${Colors.gray_500});
      font-size: var(${FontSize.small});
      background: transparent;

      &.selected,
      &:hover {
        color: var(${Colors.gray_800});
        border-color: var(${Colors.gray_100});
      }
    }
  }
`;

export const Cell = styled.div<{ theme?: Theme | 'light'; isInHeader?: boolean }>`
  ${(props) => {
    return `font-size: ${props.isInHeader ? '12px' : ''};`;
  }}
  ${(props) => {
    if (props.theme === 'red') {
      return `color: var(${Colors.danger_700});`;
    } else if (props.theme === 'green') {
      return `color: var(${Colors.success_700});`;
    } else if (props.theme === 'light') {
      return `color: var(${Colors.gray_800});`;
    } else {
      return `color: var(${Colors.gray_900});`;
    }
  }}
  &.align-right {
    display: flex;
    justify-content: end;
  }
  &.align-left {
    display: flex;
    justify-content: start;
  }
  &.dropdown {
    cursor: pointer;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
`;

export type OrderBookProps = {
  pair?: Pair;
  isPublic?: boolean;
  orderBook: any;
  isLoading?: boolean;
  style?: React.CSSProperties;
  view?: 'LeftAndRight' | 'TopAndBottom';
  level?: 'OB-L2' | 'OB-L3';
  link?: {
    label: string;
    url: string;
  };
  showCumulative?: boolean;
  onViewChanges?: (view: 'LeftAndRight' | 'TopAndBottom') => void;
  onLevelChanges?: (view: 'OB-L2' | 'OB-L3') => void;
  layout?: Layouts;
  theme?: 'gray';
  fromFlexLayout?: true;
  assetCategory?: AssetCategoryCode;
};

const OrderBook = ({ assetCategory = 'token', ...props }: OrderBookProps) => {
  const { style, onViewChanges, onLevelChanges, layout, view, level, isPublic } = props;
  const [showRestingOrders, setShowRestingOrders] = useState(false);

  const isNonBilateral = checkIfNonBilateral(assetCategory);
  return (
    <Wrapper style={style} className="flex absolute flex-col w-full h-full">
      <ViewsWrapper
        layout={layout}
        className="flex flex-row gap-3 justify-start mb-3"
        style={
          props.fromFlexLayout
            ? {
                position: 'relative',
                top: 'auto',
                left: 'auto',
              }
            : {}
        }
      >
        <div
          className="flex flex-row gap-1"
          style={{
            position: props.fromFlexLayout ? 'relative' : undefined,
            maxWidth: '100%',
            width: '100%',
            maxHeight: '25px',
            height: '25px',
          }}
        >
          {!isPublic && (
            <>
              {isNonBilateral && (
                <>
                  <button
                    className={`levelButton ${level === 'OB-L3' ? 'selected' : ''}`}
                    onClick={() => onLevelChanges?.('OB-L3')}
                  >
                    L3
                  </button>
                  <button
                    className={`levelButton ${level === 'OB-L2' ? 'selected' : ''}`}
                    onClick={() => onLevelChanges?.('OB-L2')}
                  >
                    L2
                  </button>
                </>
              )}

              {level === 'OB-L3' && isNonBilateral && (
                <div
                  className="flex overflow-hidden flex-row flex-shrink items-center"
                  style={{
                    minWidth: '100px',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    className="mr-2"
                    style={{
                      color: `var(${Colors.gray_900})`,
                      letterSpacing: '0.5',
                      fontSize: '10px',
                      fontWeight: '500',
                      height: '25px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingTop: '3px',
                      paddingLeft: '5px',
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `Show&nbspResting&nbspOrders`,
                      }}
                    />
                  </div>
                  <Toggle
                    size="small"
                    onClick={() => setShowRestingOrders(!showRestingOrders)}
                    isOn={showRestingOrders}
                  />
                </div>
              )}
            </>
          )}
          <div className="flex flex-row gap-1 ml-auto">
            <button
              className={`${view === 'LeftAndRight' ? 'selected' : ''}`}
              onClick={() => onViewChanges?.('LeftAndRight')}
            >
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
                <path d="M0 0H20V3H0V0Z" fill="#666" />
                <path d="M11 5H20V20H11V5Z" fill="#F6465D" />
                <path d="M0 5H9V20H0V5Z" fill="#0ECB81" />
              </svg>
            </button>
            <button
              className={`${view === 'TopAndBottom' ? 'selected' : ''}`}
              onClick={() => onViewChanges?.('TopAndBottom')}
            >
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
                <path d="M0 8.18182H20V10.9091H0V8.18182Z" fill="#666" />
                <path d="M0 13H20V20H0V13Z" fill="#0ECB81" />
                <path d="M0 0H20V6H0V0Z" fill="#F6465D" />
              </svg>
            </button>
          </div>
        </div>
      </ViewsWrapper>
      <>
        {assetCategory === 'biofuel' ? (
          <OrderBookBiofuel isPublic={isPublic} {...props} />
        ) : (
          <>
            {level === 'OB-L3' && !isPublic ? (
              <OrderBookL3 isPublic={isPublic} showRestingOrders={showRestingOrders} {...props} />
            ) : (
              <OrderBookL2 isPublic={isPublic} {...props} />
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};

export default OrderBook;
