interface ToVintageYearsProps {
  /**
   * Current year minus `from` years
   */
  from?: number;

  /**
   * Current year plus `to` years
   */
  to?: number;

  /**
   * If specified, should include vintages from this list
   */
  include?: Array<number>;
}

export const toVintageYears = (props: ToVintageYearsProps = {}) => {
  const { from = 10, to = 10, include = [] } = props;
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - from;
  const endYear = currentYear + to;
  const years: Array<{
    value: string;
    title: string;
  }> = [];

  for (let year = startYear; year <= endYear; year++) {
    if (!include.length) {
      years.push({
        value: '' + year,
        title: '' + year,
      });
    }
    if (include.includes(year)) {
      years.push({
        value: '' + year,
        title: '' + year,
      });
    }
  }

  return years;
};
