import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';
import { useOrderSummary } from 'refreshed-pages/market-board-v2/hooks/useOrderSummary';
import { useOtcCriteria } from 'refreshed-pages/market-board-v2/hooks/useOtcCriteria';
import { TimeInForce } from 'refreshed-pages/market-board-v2/utils/TimeInForce';
import { globalEventsEmitter } from 'refreshed-pages/market-board-v2/utils/globalEventsEmitter';

import {
  ButtonSize,
  ButtonVariant,
  IconName,
  styled,
  toSpacing,
  Text,
  Knob,
  TypographyVariant,
  Tabs,
  TabsVariant,
  TabsSize,
  Layer,
  toLayerBackground,
  toBorderColor,
  BorderColor,
  LabelWithDescription,
  TextColor,
  TextAs,
  Button,
  showToast,
  ToastVariant,
  Link,
  LinkColor,
  CMBOrderType,
} from '@aircarbon/ui';
import { AssetCategory, assetCategoryUom, Const, formatter, hooks } from '@aircarbon/utils-common';

import SelectOboAccount, { AccountDetails } from 'components/SelectOboAccount';

import { useEntity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { useActiveTab } from '../BottomSlideUp/hooks/useActiveTab';
import { FieldsGroup } from '../FieldsGroup';
import { ReviewOrderModal, ReviewOrderModalRef } from '../ReviewOrderModal';
import { AuctionForm, AuctionFormRef, AuctionFormValue } from './components/AuctionForm';
import { BidForm, BidFormRef, BidFormValue } from './components/BidForm';
import { OfferForm, OfferFormRef, OfferFormValue } from './components/OfferForm';
import { StyledSectionCard } from './components/StyledSectionCard';
import { FormValue } from './utils/FormValue';
import { createOrder } from './utils/createOrder';
import { toReviewOrderModalData } from './utils/toReviewOrderModalData';

const defaultFormValues: Record<CMBOrderType, FormValue<CMBOrderType>> = {
  [CMBOrderType.Bid]: {
    price: '',
    quantity: '',
    timeInForce: TimeInForce.GoodTillCancel,
    isInstantTrade: false,
    criteria: [],
    projects: [],
    maximumQuantity: '',
    minimumQuantity: '1000',
    quantityMultiplesOf: '1000',
    endDate: '',
  },
  [CMBOrderType.Auction]: {
    price: '',
    quantity: '',
    timeInForce: '',
    isInstantTrade: false,
    maximumQuantity: '',
    minimumQuantity: '1000',
    quantityMultiplesOf: '1000',
    vintage: '',
    auctionPeriod: '',
    showBidVolume: false,
    showBestBid: false,
    endDate: '',
  },
  [CMBOrderType.Offer]: {
    price: '',
    quantity: '',
    timeInForce: TimeInForce.GoodTillCancel,
    isInstantTrade: false,
    maximumQuantity: '',
    minimumQuantity: '1000',
    quantityMultiplesOf: '1000',
    vintage: '',
    endDate: '',
  },
};

function getQtyConfigKeys({ orderType, product }: { orderType: CMBOrderType; product: AssetCategory }) {
  if (orderType === CMBOrderType.Offer || orderType === CMBOrderType.Auction) {
    return product === AssetCategory.token
      ? { minLots: 'global_emb_bidMinLots', lotSize: 'global_mb_bid_lot_size' }
      : { minLots: 'global_emb_bidMinLots_rec', lotSize: 'global_mb_bid_lot_size_rec' };
  }

  return product === AssetCategory.token
    ? { minLots: 'global_emb_askMinLots', lotSize: 'global_mb_ask_lot_size' }
    : { minLots: 'global_emb_askMinLots_rec', lotSize: 'global_mb_ask_lot_size_rec' };
}

export const MarketBoardV2PlaceOrderPage: React.FunctionComponent<{
  formValue?: FormValue<CMBOrderType>;
  orderType?: CMBOrderType;
  isSubmittingOrder?: boolean;
  title?: string;

  /**
   * If set to true, will only allow to edit price and quantity fields
   */
  isEditing?: boolean;

  /**
   * Hide assets reserved toggle
   */
  isAssetsReservedHidden?: boolean;
  onBack?(): void;
  onConfirm?(props: { assetCategoryId: string; formValue: FormValue<CMBOrderType>; creatorUserId: number }): void;
}> = (props) => {
  const {
    title = 'Place Order',
    formValue: formValueProp,
    orderType: orderTypeProp,
    isSubmittingOrder: isSubmittingOrderProp,
    isAssetsReservedHidden,
    isEditing,
    onBack,
    onConfirm,
  } = props;
  const { updateState } = useActiveTab();
  const { product } = useMarketplaceProduct();
  const prevProduct = hooks.usePrevious(product);
  const [orderType, setOrderType] = useState(orderTypeProp || CMBOrderType.Bid);
  const { getSetting } = UI.useContainer();

  const minQtyKey = getQtyConfigKeys({ orderType, product: Number(product) as AssetCategory });
  const minLots = Number(getSetting(minQtyKey.minLots));
  const lotSize = Number(getSetting(minQtyKey.lotSize));
  const minimumQuantity = String(minLots * lotSize);
  defaultFormValues[orderType].minimumQuantity = minimumQuantity;
  defaultFormValues[orderType].quantityMultiplesOf = String(lotSize);

  const [formValue, setFormValue] = useState(formValueProp || defaultFormValues[orderType]);
  const history = useHistory();
  const bidFormRef = useRef<BidFormRef>(null);
  const offerFormRef = useRef<OfferFormRef>(null);
  const auctionFormRef = useRef<AuctionFormRef>(null);
  const {
    selector: { getAccountAddress, getUserId, getFullName },
    status: { canCmbBidObo, canManageCmbBid: checkCanManageCMBBid, canCreateAuction: checkCanCreateAuction },
  } = User.useContainer();
  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });
  const { availableBalance, estimatedTotal, fee, isCalculatingFee, isLoadingBalance } = useOrderSummary({
    orderType: orderType,
    price: Number(formValue.price || 0),
    quantity: Number(formValue.quantity || 0),
    userId: selectedAccount.userId,
  });
  const { meta } = useOtcCriteria();
  const reviewOrderModalRef = useRef<ReviewOrderModalRef>(null);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(isSubmittingOrderProp ?? false);

  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();

  const currencyCode = selector.mainCcySymbol;
  const numDecimals = selector.mainCcyNumDecimals;

  const canCreateAuction = checkCanCreateAuction();
  const canManageCmbBid = checkCanManageCMBBid();

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const availableTabs = useMemo(() => {
    const tabs: Array<{
      label: string;
      id: string;
    }> = [
      canManageCmbBid && {
        id: CMBOrderType.Bid,
        label: 'Bid',
      },
      canManageCmbBid && {
        id: CMBOrderType.Offer,
        label: 'Offer',
      },
      canCreateAuction && {
        id: CMBOrderType.Auction,
        label: 'Auction',
      },
    ].filter((t) => !!t) as Array<{
      label: string;
      id: string;
    }>;

    return tabs;
  }, [canManageCmbBid, canCreateAuction]);

  useEffect(() => {
    if (availableTabs.find((tab) => tab.id === orderType)) {
      return;
    }

    const minQtyKey = getQtyConfigKeys({ orderType, product: Number(product) as AssetCategory });
    const minLots = Number(getSetting(minQtyKey.minLots));
    const lotSize = Number(getSetting(minQtyKey.lotSize));
    const minimumQuantity = String(minLots * lotSize);

    setFormValue({
      ...defaultFormValues[availableTabs[0].id as CMBOrderType],
      minimumQuantity,
      quantityMultiplesOf: String(lotSize),
    });
    setOrderType(availableTabs[0].id as CMBOrderType);
  }, [orderType, availableTabs]);

  useEffect(() => {
    if (formValueProp) {
      return;
    }

    if (prevProduct && product !== prevProduct) {
      const minQtyKey = getQtyConfigKeys({ orderType, product: Number(product) as AssetCategory });
      const minLots = Number(getSetting(minQtyKey.minLots));
      const lotSize = Number(getSetting(minQtyKey.lotSize));
      const minimumQuantity = String(minLots * lotSize);

      setFormValue({ ...defaultFormValues[orderType], minimumQuantity, quantityMultiplesOf: String(lotSize) });
    }
  }, [orderType, product, prevProduct, formValueProp]);

  useEffect(() => {
    setIsSubmittingOrder(isSubmittingOrderProp ?? false);
  }, [isSubmittingOrderProp]);

  const onPressOrderTypeTab = ({ id }: { id: CMBOrderType }) => {
    setFormValue(defaultFormValues[id]);
    setOrderType(id);
  };

  const onPressReview = () => {
    let hasValidationErrors: boolean = false;

    if (orderType === CMBOrderType.Bid) {
      hasValidationErrors = bidFormRef.current?.validate() || false;
    }

    if (orderType === CMBOrderType.Offer) {
      hasValidationErrors = offerFormRef.current?.validate() || false;
    }

    if (orderType === CMBOrderType.Auction) {
      hasValidationErrors = auctionFormRef.current?.validate() || false;
    }

    if (hasValidationErrors) {
      return;
    }

    reviewOrderModalRef.current?.show(
      toReviewOrderModalData({
        formValue,
        orderType,
        currency: currencyCode,
        quantityUnit: uom,
        total: estimatedTotal,
        fee,
        placedBy: getFullName(),
        onBehalfOf: selectedAccount.fullName,
        projectMeta: meta,
        isOtcSettlementEnabled: isMarketBoardAndAuctionsV2SettlementModeEnabled,
      }),
    );
  };

  const onPressBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    history.goBack();
  };

  const onConfirmOrder = async () => {
    if (onConfirm) {
      onConfirm({
        assetCategoryId: product,
        formValue,
        creatorUserId: selectedAccount.userId,
      });

      return;
    }

    setIsSubmittingOrder(true);
    const result = await createOrder({
      assetCategoryId: product,
      orderType,
      formValue,
      creatorUserId: selectedAccount.userId,
    });

    if (result.status === 'ok') {
      globalEventsEmitter.emit('orderCreated');
      history.push('/account/mb-and-auctions');

      showToast({
        variant: ToastVariant.Success,
        message: (
          <Text variant={TypographyVariant.subtitle2} color={TextColor.inverse}>
            You have successfully placed a {orderType}! you can review it in{' '}
            <Link
              color={LinkColor.Primary}
              onPress={() => {
                updateState(() => ({ activeTabId: 'my-orders', isOpened: true }));
              }}
            >
              My Orders
            </Link>
          </Text>
        ),
      });
      return;
    }

    setIsSubmittingOrder(false);

    showToast({
      variant: ToastVariant.Danger,
      message: result.error,
    });
  };

  const onChangeFormValue = <T extends CMBOrderType>(newFormValue: FormValue<T>) => {
    setFormValue(newFormValue);
  };

  const isReviewDisabled = isCalculatingFee || (formValue.isInstantTrade && estimatedTotal > availableBalance);

  return (
    <>
      <ReviewOrderModal isSubmitting={isSubmittingOrder} ref={reviewOrderModalRef} onConfirm={onConfirmOrder} />
      <Wrapper>
        <HeaderAndContent>
          <Header>
            <TitleWithBack>
              <Knob
                variant={ButtonVariant.outlined}
                size={ButtonSize.xs}
                icon={IconName.ChevronStart}
                onPress={onPressBack}
              />
              <Text variant={TypographyVariant.subtitle1}>{title}</Text>
            </TitleWithBack>
          </Header>
          <Content>
            <Layer>
              {!orderTypeProp && (
                <Tabs
                  activeItemId={orderType}
                  variant={TabsVariant.Contained}
                  size={TabsSize.m}
                  items={availableTabs}
                  onPressTab={onPressOrderTypeTab}
                />
              )}
              {canCmbBidObo() && (
                <StyledSectionCard>
                  <FieldsGroup columnMinWidth="14rem">
                    <div className="flex flex-row gap-large">
                      <div className="flex flex-col flex-1 gap-2xs">
                        <Text>On Behalf Of</Text>
                        <SelectOboAccount
                          name="OBO"
                          inputValue={selectedAccount.account}
                          setFieldValue={(_selectedField, value) => {
                            setSelectedAccount({
                              account: value.value,
                              userId: value.user_id,
                              fullName: value.fullName,
                            });
                          }}
                        />
                        <div className="flex text-xs">
                          <div>Available Balance:</div>
                          <div className="relative pl-2">
                            {isLoadingBalance ? (
                              <Loading size="small" />
                            ) : (
                              <span>
                                {currencyCode}
                                {formatter.formatNumber(availableBalance, numDecimals)}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FieldsGroup>
                </StyledSectionCard>
              )}

              {orderType === CMBOrderType.Bid && (
                <BidForm
                  isEditing={isEditing}
                  onChange={onChangeFormValue}
                  ref={bidFormRef}
                  value={formValue as BidFormValue}
                  isInstantTradeHidden
                />
              )}
              {orderType === CMBOrderType.Offer && (
                <OfferForm
                  isEditing={isEditing}
                  ownedByUserId={selectedAccount.userId}
                  ref={offerFormRef}
                  value={formValue as OfferFormValue}
                  onChange={onChangeFormValue}
                  isInstantTradeHidden={isAssetsReservedHidden}
                />
              )}
              {orderType === CMBOrderType.Auction && (
                <AuctionForm
                  isEditing={isEditing}
                  ownedByUserId={selectedAccount.userId}
                  ref={auctionFormRef}
                  value={formValue as AuctionFormValue}
                  onChange={onChangeFormValue}
                  isInstantTradeHidden
                />
              )}
            </Layer>
          </Content>
        </HeaderAndContent>
        <Layer>
          <Footer>
            <FooterContent>
              {!isMarketBoardAndAuctionsV2SettlementModeEnabled ? (
                <LabelWithDescription
                  label="Available Balance"
                  description={new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyCode,
                  }).format(availableBalance)}
                />
              ) : null}
              <LabelWithDescription
                label="Estimated Total"
                description={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: currencyCode,
                }).format(estimatedTotal)}
                descriptionSuffix={
                  <Text as={TextAs.span} color={TextColor.secondary}>
                    {isCalculatingFee
                      ? 'calculating fee...'
                      : `
                        (fee ~ ${new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currencyCode,
                        }).format(fee)})
                        `}
                  </Text>
                }
              />
              <Button variant={ButtonVariant.secondary} onPress={onPressReview} isDisabled={isReviewDisabled}>
                Review
              </Button>
            </FooterContent>
          </Footer>
        </Layer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: ${({ theme }) => toSpacing(theme)(12)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

const TitleWithBack = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.04);
  padding: ${({ theme }) => `${toSpacing(theme)(8)} ${toSpacing(theme)(12)}`};
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 47rem;
  gap: ${({ theme }) => toSpacing(theme)(16)};
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
  }

  > *:nth-last-child(2) {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
