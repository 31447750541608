import type { CognitoUser } from '@aws-amplify/auth';
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import type * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AuthWrapper } from 'refreshed-component/molecules/AuthWrapper';
import { toast } from 'refreshed-component/molecules/toast';

import {
  InputText,
  Text,
  TextColor,
  InputTextType,
  TypographyVariant,
  Button,
  ButtonVariant,
  ButtonSize,
  ButtonType,
  styled,
  useLayerBackground,
} from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';

import useQueryParams from 'hooks/useQueryParams';

import emitter from 'utils/emitter';

import EmailOtp from './EmailOtp';
import MFA from './MFA';

export const SignIn: React.FunctionComponent = () => {
  const { entity } = Entity.useContainer();
  const registerLink = entity.config?.registerLink;
  const history = useHistory();
  const query = useQueryParams();
  const [email, setEmail] = useState(query.get('email') ?? '');
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: email,
      resetPassUsername: email,
      password: '',
      password_repeat: '',
      code: '',
      new_password: '',
      forgotPasswordCodeSent: false,
    },
    shouldUnregister: false,
  });

  const [step, setStep] = useState('SIGN_IN');
  const [resetPasswordCodeSent, setResetPasswordCodeSent] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [user, setUser] = useState<CognitoUser>();

  const password = useRef({});
  password.current = watch('password', '');

  const signIn = async (username: string, password: string) => {
    setIsSaving(true);
    setSubmitError('');
    setEmail(username);
    try {
      const signinUser = await Auth.signIn(username, password);
      if (signinUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(signinUser);
        setStep('NEW_PASSWORD_REQUIRED');
      } else if (signinUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setUser(signinUser);
        setStep('ENTER_AUTHENTICATOR_CODE');
      } else if (signinUser?.challengeName === 'CUSTOM_CHALLENGE') {
        setUser(signinUser);
        setStep('ENTER_EAMIL_OTP');
      } else {
        // NOTE: This is a hack to force a reload of the page and clear the cache.
        window.location.reload();
      }
      setIsSaving(false);
    } catch (err: any) {
      setIsSaving(false);
      setSubmitError('Wrong email and/or password');
    }
  };

  const changePassword = useCallback(
    async (formData: any) => {
      setIsSaving(true);
      setSubmitError('');

      try {
        const userName = (user as any)?.username;
        const session = (user as any)?.Session;
        const newPassword = formData.password;
        const data = await fetch(`/api/user/user/complete-new-password-challenge`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userName,
            session,
            newPassword,
          }),
        });
        const result = await data.json();
        if (!result?.ok) {
          setIsSaving(false);
          setSubmitError(result?.statusText ?? 'Error, cannot validate password');
          return;
        }
        toast.success('Password saved successfully');
        // Log user out to get new session also to get new token without admin scope
        goToStart();
      } catch (err: any) {
        setIsSaving(false);
        setSubmitError((err as Error).message);
      }
    },
    [user],
  );

  const sendEmailOtp = useCallback(
    async (code: string) => {
      setIsSaving(true);
      setSubmitError('');

      try {
        // TODO: Implement data-mutation
        const userName = (user as any)?.username;
        const session = (user as any)?.Session;
        const data = await fetch(`/api/user/user/respond-to-auth-challenge`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code,
            session,
            userName,
            challengeName: 'CUSTOM_CHALLENGE',
          }),
        });

        const result = await data.json();
        if (!result?.ok) {
          toast.error(result?.statusText ?? 'Error, cannot validate otp');
          setIsSaving(false);
          setSubmitError(result?.statusText ?? 'Error, cannot validate otp');
          return;
        }
        const userSession = new CognitoUserSession({
          IdToken: new CognitoIdToken({ IdToken: result?.data?.IdToken }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: result?.data?.RefreshToken }),
          AccessToken: new CognitoAccessToken({ AccessToken: result?.data?.AccessToken }),
        });
        const currentUser = await (Auth as any).createCognitoUser(
          userSession.getIdToken().decodePayload()['cognito:username'],
        );
        await currentUser.setSignInUserSession(userSession);
        setUser(currentUser);
        setIsSaving(false);
        window.location.reload();
      } catch (err: any) {
        setIsSaving(false);
        toast.error((err as Error).message);
        setSubmitError((err as Error).message);
      }
    },
    [user],
  );

  const resetValues = () => {
    setIsSaving(false);
    setSubmitError('');
    setResetPasswordCodeSent(false);
  };

  const goToStart = useCallback(() => {
    resetValues();
    setStep('SIGN_IN');
  }, []);

  // reset to sign in
  useEffect(() => {
    const onListener = () => {
      goToStart();
    };
    emitter.on('FORCE_LOGOUT', onListener);

    return () => {
      emitter.off('FORCE_LOGOUT', onListener);
    };
  }, [goToStart]);

  useEffect(() => {
    resetValues();
  }, [step]);

  const emailControlRules = {
    required: 'Required',
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' },
  };

  const { layerBackground } = useLayerBackground();

  return (
    <AuthWrapper notLogged={true}>
      {step === 'SIGN_IN' && (
        <>
          <form key="SIGN_IN" onSubmit={handleSubmit((formData) => signIn(formData.username, formData.password))}>
            <input type="hidden" name="username" value={email} />
            <div className="flex flex-col gap-small">
              <Text variant={TypographyVariant.h4Title}>Log in to your account</Text>
              <Text variant={TypographyVariant.body1} color={TextColor.secondary}>
                Welcome back! Please enter your details.
              </Text>
            </div>
            <div className="flex flex-col flex-1 items-stretch w-full gap-base">
              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="username"
                  rules={emailControlRules}
                  control={control}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      type={InputTextType.Email}
                      placeholder="Email address"
                      label="Email"
                      error={errors.username?.message}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col flex-1 items-stretch w-full gap-base">
                <div className="flex flex-col flex-1 gap-xs">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        value={field.value}
                        onChange={field.onChange}
                        type={InputTextType.Password}
                        placeholder="Password"
                        label="Password"
                        error={errors.password?.message}
                      />
                    )}
                  />
                </div>
              </div>
              {submitError !== '' && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {submitError}
                </Text>
              )}
              <Button type={ButtonType.Submit} className="flex-1" isLoading={isSaving}>
                Sign In
              </Button>
              <Button
                variant={ButtonVariant.ghost}
                size={ButtonSize.xs}
                onPress={() => {
                  setStep('FORGOT_PASSWORD');
                }}
              >
                Forgot password
              </Button>
              <div className="flex relative flex-row flex-1 justify-center w-full mt-xs">
                <div className="flex absolute z-0 flex-row justify-center items-center w-full h-full">
                  <div className="w-full border-t border-gray_200" />
                </div>
                <StyledDontHaveAccountText
                  background={layerBackground('layer')}
                  variant={TypographyVariant.body2}
                  className="z-10"
                  color={TextColor.secondary}
                >
                  Don't have an account?
                </StyledDontHaveAccountText>
              </div>
              <Button
                variant={ButtonVariant.outlined}
                onPress={(e) => {
                  e.preventDefault();
                  history.push('/market?type=carbon');
                }}
              >
                Explore Markets
              </Button>

              {!!registerLink && (
                <Button
                  variant={ButtonVariant.ghost}
                  onPress={(e) => {
                    e.preventDefault();
                    window.open(registerLink);
                  }}
                  size={ButtonSize.s}
                >
                  Request to Register
                </Button>
              )}
            </div>
          </form>
          <FormDevTool control={control} />
        </>
      )}
      {step === 'ENTER_AUTHENTICATOR_CODE' && user && <MFA signInUser={user} onCancel={goToStart} />}
      {step === 'ENTER_EAMIL_OTP' && user && (
        <EmailOtp email={email} disable={isSaving} onSubmit={sendEmailOtp} onCancel={goToStart} />
      )}
      {step === 'FORGOT_PASSWORD' && (
        <form
          key="FORGOT_PASSWORD"
          onSubmit={handleSubmit(async (formData) => {
            setIsSaving(true);
            logger.warn(formData);

            if (!resetPasswordCodeSent) {
              setEmail(formData.resetPassUsername);
              await Auth.forgotPassword(formData.resetPassUsername)
                .then(() => {
                  setResetPasswordCodeSent(true);
                  setIsSaving(false);
                })
                .catch((err) => {
                  setIsSaving(false);
                  setSubmitError((err as Error).message);
                });
            } else {
              await Auth.forgotPasswordSubmit(email, formData.code, formData.new_password)
                .then(async () => {
                  signIn(email, formData.new_password);
                })
                .catch((err) => {
                  setIsSaving(false);
                  setSubmitError((err as Error).message);
                });
            }
          })}
          autoComplete="off"
        >
          <div className="w-full">
            <Text variant={TypographyVariant.h4Title}>Reset Password</Text>
          </div>
          <div className="flex flex-col flex-1 items-stretch w-full gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Controller
                name="resetPassUsername"
                rules={emailControlRules}
                control={control}
                render={({ field }) => (
                  <InputText
                    value={field.value}
                    onChange={field.onChange}
                    type={InputTextType.Email}
                    placeholder="Email address"
                    label={resetPasswordCodeSent ? 'For' : "Enter your account's email"}
                    error={errors.resetPassUsername?.message}
                  />
                )}
              />
            </div>
          </div>
          {resetPasswordCodeSent && (
            <div className="flex flex-col flex-1 items-stretch w-full gap-base">
              <Text variant={TypographyVariant.subtitle2}>We sent a confirmation code to your email.</Text>
              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Enter code"
                      label={'Code *'}
                      error={errors.code?.message}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="new_password"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      type={InputTextType.Password}
                      placeholder="Password"
                      label="New password *"
                      error={errors.new_password?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
          {submitError !== '' && (
            <Text variant={TypographyVariant.body2} color={TextColor.error}>
              {submitError}
            </Text>
          )}
          <div className="flex flex-row flex-1 w-full gap-medium">
            <Button
              className="flex-1"
              variant={ButtonVariant.outlined}
              type={ButtonType.Button}
              onPress={(e) => {
                e.preventDefault();
                goToStart();
              }}
            >
              Back
            </Button>
            <Button
              className="flex-1"
              type={ButtonType.Submit}
              isLoading={isSaving || (isSaving && resetPasswordCodeSent)}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
      {step === 'NEW_PASSWORD_REQUIRED' && (
        <>
          <FormDevTool control={control} />
          <form key="NEW_PASSWORD_REQUIRED" onSubmit={handleSubmit(changePassword)}>
            <div className="flex flex-col gap-small">
              <Text variant={TypographyVariant.h4Title}>Good to see you again,</Text>
            </div>
            <div className="flex flex-col flex-1 items-stretch w-full gap-base">
              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="username"
                  rules={emailControlRules}
                  control={control}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      type={InputTextType.Email}
                      placeholder="Email address"
                      label="Email"
                      isDisabled
                      error={errors.username?.message}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: 'You must specify a password',
                    minLength: {
                      value: 9,
                      message: 'Password must have at least 9 characters',
                    },
                  }}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      type={InputTextType.Password}
                      placeholder="New password"
                      label="Password"
                      description="Please set your password to sign in."
                      error={errors.password?.message}
                    />
                  )}
                />
              </div>

              <div className="flex flex-col flex-1 gap-xs">
                <Controller
                  name="password_repeat"
                  control={control}
                  rules={{
                    validate: (value) => value === password.current || 'The passwords do not match',
                  }}
                  render={({ field }) => (
                    <InputText
                      value={field.value}
                      onChange={field.onChange}
                      type={InputTextType.Password}
                      placeholder="Repeat password"
                      label={'Repeat password'}
                      error={errors.password_repeat?.message}
                    />
                  )}
                />
              </div>
            </div>
            {submitError !== '' && (
              <Text variant={TypographyVariant.body2} color={TextColor.error}>
                {submitError}
              </Text>
            )}
            <div className="flex flex-row flex-1 w-full gap-medium">
              <Button
                variant={ButtonVariant.outlined}
                className="flex-1"
                onPress={() => goToStart()}
                isDisabled={isSaving}
                type={ButtonType.Button}
              >
                Back
              </Button>
              <Button className="flex-1" type={ButtonType.Submit} isLoading={isSaving}>
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </AuthWrapper>
  );
};

export default SignIn;

const StyledDontHaveAccountText = styled(Text)<{
  background: string;
}>`
  background: ${({ background }) => background};
`;
