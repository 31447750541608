import { flatten } from 'lodash';
import { TimeInForce } from 'refreshed-pages/market-board-v2/utils/TimeInForce';

import { CMBOrderType } from '@aircarbon/ui';

import { AuctionFormValue } from '../../PlaceOrderPage/components/AuctionForm';
import { BidFormValue } from '../../PlaceOrderPage/components/BidForm';
import { OfferFormValue } from '../../PlaceOrderPage/components/OfferForm';

type FormValue<OrderType extends CMBOrderType> = OrderType extends CMBOrderType.Bid
  ? BidFormValue
  : OrderType extends CMBOrderType.Auction
    ? AuctionFormValue
    : OfferFormValue;

export const toEditOtcOrderBody = <OT extends CMBOrderType>(props: { orderType: OT; formValue: FormValue<OT> }) => {
  const { orderType, formValue } = props;

  if (orderType === CMBOrderType.Bid) {
    return {
      qty: Number(formValue.quantity),
      ...(!!formValue.price
        ? {
            price: Number(formValue.price),
          }
        : {}),
      timeInForceTypeId: Number(TimeInForce.GoodTillCancel),
      isPrefunded: formValue.isInstantTrade,

      ...((formValue as BidFormValue).criteria.length || (formValue as BidFormValue).projects.length
        ? {
            criteria: [
              ...flatten(
                (formValue as BidFormValue).criteria.map((item) => {
                  const singleValues = item.value.split(',');

                  return singleValues.map((singleValue) => ({
                    key: item.key,
                    valueFrom: singleValue,
                    operator: 'IN',
                  }));
                }),
              ),
              ...(formValue as BidFormValue).projects.map((project) => ({
                key: 'PROJECT_ID',
                valueFrom: project.value,
                operator: 'IN',
              })),
            ],
          }
        : {}),
      showBestBid: (formValue as AuctionFormValue).showBestBid,
      showBidVolume: (formValue as AuctionFormValue).showBidVolume,
      ...(!!formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
      ...(!!formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
      ...(!!formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
    };
  }
  if (orderType === CMBOrderType.Auction) {
    const [startDateISO, endDateISO] = (formValue as AuctionFormValue).auctionPeriod.split(',');

    return {
      qty: Number(formValue.quantity),
      price: Number(formValue.price),
      startDate: startDateISO,
      endDate: endDateISO,
      isPrefunded: formValue.isInstantTrade,
      projectId: Number((formValue as AuctionFormValue).project?.value),
      timeInForceTypeId: Number(TimeInForce.GoodTillDate),
      criteria: [
        {
          key: 'VINTAGE_YEAR',
          valueFrom: (formValue as AuctionFormValue).vintage,
          operator: 'IN',
        },
      ],
      ...(!!formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
      ...(!!formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
      ...(!!formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
    };
  }

  return {
    qty: Number(formValue.quantity),
    price: Number(formValue.price),
    timeInForceTypeId: Number(TimeInForce.GoodTillCancel),
    projectId: Number((formValue as OfferFormValue).project?.value),
    criteria: [
      {
        key: 'VINTAGE_YEAR',
        valueFrom: (formValue as OfferFormValue).vintage,
        operator: 'IN',
      },
    ],
    isPrefunded: formValue.isInstantTrade,
    ...(!!formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
    ...(!!formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
    ...(!!formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
  };
};
