import { Asset, AssetCategory } from './pair';
import { Project } from './project';
import { TradeRequestDto } from './trading';
import { User } from './user';

export enum MbSupportedAssetCategory {
  token = 1,
  rec = 5,
}

export enum OtcOrderStatus {
  Pending = 1,
  Active = 2,
  PartiallyFilled = 3,
  Filled = 4,
  Cancelled = 5,

  // For auction orders
  Closed = 6,
}

export interface OtcOrderCriteria {
  key: string;
  operator: 'IN' | 'NOT IN';
  valueFrom: string | number | string[] | number[];
  valueTo?: string | number;
}

export enum OtcOrderSortBy {
  Recent = 'createdAtUtc',
  Price = 'price',
  Id = 'id',
}

export enum TimeInForceSortBy {
  Id = 'id',
  UiOrder = 'uiOrder',
}

export enum OfferRejectionReason {
  CreditNotAvailable = 'Credits no longer available',
  LowQuantity = 'Quantity too low',
  LowPrice = 'Price too low',
}

export interface OtcOrder {
  id: number;
  sideId: number;
  price: number | null;
  qty: number;
  projectId: number;
  startAtUtc: Date | null;
  expiryUtc: Date | null;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  tradeFee: number | null;
  isAuction: number;
  isPrefunded: number;
  statusReason: string | null;
  baseAssetCategoryId: number;
  baseAssetId: number;
  quoteAssetId: number;
  minQty: number | null;
  maxQty: number | null;
  qtyMultipleOf: number | null;
  user: User;
  __user__: User;
  project: Project;
  __project__: Project;
  userId: number;
  placedByUser: User;
  placedBy: number;
  baseAssetCategory?: AssetCategory;
  quoteAsset?: Asset;
  baseAsset?: Asset;
  accountAddress: string;
  timeInForceTypeId: number;
  statusId: number;
  otcTradeRequests: TradeRequestDto[];
  filledQty: number;
  numNewRequests?: number;
  numOpenRequests?: number;
  reservedFiatAmount?: number;
  reservedAssetQty?: number;
  criteria: Array<{
    key: string;
    values: Array<string>;
  }>;
  showBestOffer: number;
  showVolume: number;
  auctionRulesLink: string | null;
}
