import type { CognitoUserInterface } from '@aws-amplify/ui-components';
import * as Sentry from '@sentry/browser';
import { Auth, Hub } from 'aws-amplify';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { toast } from 'refreshed-component/molecules/toast';
import { createContainer } from 'unstated-next';

import { UserType, helpers, logger, Const } from '@aircarbon/utils-common';
import type { MfaType } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import useAcceptDocument from 'hooks/useAcceptDocument';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchWebUserSettings } from 'data-provider/setting/fetchUserWebSettings';
import { fetchUserDevices } from 'data-provider/user/fetchUserDevices';
import { fetchUserFullProfile } from 'data-provider/user/fetchUserFullProfile';

import { parseBankInfo } from 'utils/bankAccount';
import emitter from 'utils/emitter';
import { getRoleName } from 'utils/helpers';

const { revealMessage } = helpers;

export async function logout() {
  return Promise.all([Auth.forgetDevice(), Auth.signOut()]).catch((error) => {
    logger.error(error, 'Error signing out');
  });
}

/**
 * Checks if user has many devices signed in.
 */
async function hasManyDevices() {
  try {
    const user = await Auth.currentAuthenticatedUser();

    logger.info(
      `Checking if user ${user.username}/${user?.signInUserSession?.idToken?.payload?.email} has many devices`,
    );
    if (user?.signInUserSession?.idToken?.payload?.email) {
      const response = await fetch('/api/user/multi-access', {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        },
        body: JSON.stringify({
          email: user?.signInUserSession?.idToken?.payload?.email,
        }),
      });

      if (response.ok) {
        const { data } = await response.json();
        logger.info(data, 'multi-access');
        // by pass the multi access check
        if (data?.hasEnableMultiAccess) {
          return false;
        }
      }
    }

    const result = await fetchUserDevices();
    if (!result.ok) {
      return false;
    }
    logger.info(result, 'devices');

    return result?.data.length > 1;
  } catch {
    return false;
  }
}

const userSettingsUrl = '/api/user/user/web-settings';
/**
 * Checks if user has whitelisted IP addresses.
 */
async function hasWhitelistedIPAddresses() {
  try {
    const response = await fetchWebUserSettings();
    return response;
  } catch (err) {
    logger.error(err, 'Error fetching');
    return false;
  }
}

export function useUser() {
  const { getSetting, setUserAuthenticated } = UI.useContainer();
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { tokenTypesGER } = useTokenTypes();
  const [user, setUser] = useState<CognitoUserInterface | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<
    | {
        user: Record<string, any>;
        customer?: Record<string, any>;
        profile: Record<string, any>;
        account?: Record<string, any>;
        permissions?: Array<string>;
        bankAccount?: Record<string, any>;
        rootUser: Record<string, any>;
      }
    | undefined
  >(undefined);
  const [bankAccount, setBankAccount] = useState(parseBankInfo(''));
  const queryCache = useQueryCache();
  const disableMultiAccess = Number(getSetting('web_settings_enable_preventMultiDeviceLogin')) === 1;
  const resetUser = () => {
    setUser(undefined);
    setUserInfo(undefined);
    setUserAuthenticated(undefined);
    setBankAccount(parseBankInfo(''));
  };

  const fetchFullProfile = useCallback(async () => {
    try {
      const profileResponse = await fetchUserFullProfile(
        user?.signInUserSession?.idToken?.payload?.sub,
        `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
      );

      let customerInfo;
      try {
        const response = await fetch(`/api/kyc-info?sub=${user?.signInUserSession?.idToken?.payload?.sub}`, {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
          },
        });
        customerInfo = await response.json();
      } catch (error) {
        logger.error(error, 'Error fetching cynopsis customer info');
        // Consider if you want to throw the error or handle it specifically here
      }

      const fullProfile =
        customerInfo?.status === 'success' ? { ...profileResponse, customer: customerInfo?.data } : profileResponse;

      setUserInfo(fullProfile);
      return fullProfile;
    } catch (error) {
      logout().then(() => {
        resetUser();
        emitter.emit('FORCE_LOGOUT');
        toast.error('User data Error');
      });
      emitter.emit('FORCE_LOGOUT');
    }
  }, [user, resetUser, setUserInfo]);

  // load profile after signed in
  useEffect(() => {
    if (user) {
      fetchFullProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.signInUserSession?.idToken?.payload?.sub]);
  const fetchUser = async () => {
    try {
      setIsLoadingUser(true);
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        // Identify Your Users for Sentry
        Sentry.setUser({
          id: user.signInUserSession?.idToken?.payload?.sub, // set AWS Cognito ID as key
          email: user.signInUserSession?.idToken?.payload?.email,
          username: user.username,
        });
      }
      setIsLoadingUser(false);
      setUser(user);
      setUserAuthenticated('true');
    } catch {
      setIsLoadingUser(false);
    }
  };

  // check whitelisted ip
  useEffect(() => {
    async function checkIpWhitelisted() {
      const isWhitelisted = await hasWhitelistedIPAddresses();
      if (!isWhitelisted) {
        toast.warn('You are not whitelisted. Unable to log in.');
        // give something for UI to show toast message
        setTimeout(() => {
          logout().then(() => {
            resetUser();
          });
        }, 500);
      }
    }
    if (userInfo?.user) {
      checkIpWhitelisted();
    }
  }, [userInfo?.user]);

  // check user authentication on rendering
  useEffect(() => {
    async function detectMultiAccess() {
      if (disableMultiAccess) {
        const forceLogout = await hasManyDevices();
        if (forceLogout) {
          const confirmed = window.confirm(
            'You are currently logged in from another device or browser. If you wish to continue we will close all active sessions first.',
          );
          if (confirmed) {
            const currentUser = await Auth.currentAuthenticatedUser();
            logger.warn(currentUser, 'forceLogout');
            fetch('/api/user-force-logout', {
              headers: {
                accept: 'application/json',
                authorization: `Bearer ${currentUser?.signInUserSession?.accessToken?.jwtToken}`,
              },
            })
              .then((resp: Response) => resp.json())
              .catch(logger.error.bind(logger));
            logout().then(() => {
              resetUser();
              emitter.emit('FORCE_LOGOUT');
            });
            return;
          }
        }
      }
    }

    async function handleUserAuthentication({ payload: { event, data } }: any) {
      switch (event) {
        case 'signIn':
          try {
            await detectMultiAccess();
            await fetchUser();
          } catch (error) {
            logger.error(error, 'handle user authentication error');
          }

          break;
        case 'signOut':
        case 'tokenRefresh_failure':
          setUser(undefined);
          break;
        default:
          logger.info(
            {
              event,
              data,
            },
            'handleUserAuthentication',
          );
      }
    }
    Hub.listen('auth', handleUserAuthentication);

    fetchUser();

    return () => {
      Hub.remove('auth', handleUserAuthentication);
    };
  }, [disableMultiAccess]);

  const fetchUserSettings = useCallback(async () => {
    try {
      const response = await fetchWebUserSettings();
      return response;
    } catch (error) {
      toast.warn('You do not have permission to access this page');
      logout().then(() => {
        resetUser();
      });
      throw new Error('You do not have permission to access this page');
    }
  }, [resetUser]);

  const { data: userSettings } = useQuery(userSettingsUrl, fetchUserSettings, {
    enabled: !!userInfo?.user,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!user) {
      setUserInfo(undefined);
      setUserAuthenticated(undefined);
    }
    if (userInfo?.bankAccount?.bankAccount) {
      setBankAccount(parseBankInfo(revealMessage(userInfo?.bankAccount?.bankAccount)));
    }
  }, [user, userInfo]);

  let TCFilename = getSetting('web_settings_latestTCFilenameVersion');

  if (userInfo?.user?.account_type === UserType.OFFMARKET_SELLER) {
    TCFilename = getSetting('web_settings_embSellerTCFilenameVersion');
  }

  const superAdminIds = getSetting('web_settings_superAdminsIds');
  const allowExternalBrokerClientForBlockTrade = getSetting('blockTrade_allowExternalBrokerClient') === '1';
  const allowExternalBrokerClientForBiofuel = getSetting('global_biofuel_allowExternalBrokerClient') === '1';
  const {
    isLoading: isLoadingAcceptedTerms,
    selectors: { hasAccepted },
    actions: { refetchTcAccepted },
  } = useAcceptDocument(TCFilename);
  const rootUser: Record<string, any> = userInfo?.rootUser ?? userInfo?.profile ?? {};

  const requireAgreePlatformTerm = () => Number(getSetting('web_settings_require_agree_platform_term')) === 1;
  const requireAgreeCMBTerm = () => Number(getSetting('web_settings_require_agree_cmb_term')) === 1;
  const bypassTCs: string[] = [UserType.OFFMARKET_BUYER];

  const isMarketUserTypes = [
    UserType.CORPORATE_ADMIN,
    UserType.CORPORATE_TRADER,
    UserType.CORPORATE_MONITOR,
    UserType.CORPORATE_CLIENT_DMA,
    UserType.CORPORATE_CLIENT_READ_ONLY,
    UserType.INDIVIDUAL,
    UserType.MEMBER_ADMIN,
    UserType.MEMBER_TRADER,
    UserType.MEMBER_CLIENT,
  ];

  const isBiofuelFeatureEnabled = getSetting(Const.FeatureToggle.Biofuel) === '1';
  const isGERFeatureEnabled = getSetting(Const.FeatureToggle.GER) === '1';
  const isCashDepositFeatureEnabled = getSetting(Const.FeatureToggle.CashDeposit) === '1';
  const isCashWithdrawFeatureEnabled = getSetting(Const.FeatureToggle.CashWithdraw) === '1';
  const isDeliveryFeatureEnabled = getSetting(Const.FeatureToggle.Delivery) === '1';
  const isRetirementFeatureEnabled = getSetting(Const.FeatureToggle.Retirement) === '1';
  const isSpotExchangeFeatureEnabled = getSetting(Const.FeatureToggle.SpotCarbon) === '1';
  const isBlockTradesFeatureEnabled = getSetting(Const.FeatureToggle.BlockTrade) === '1';
  const isCarbonFinderFeatureEnabled = getSetting(Const.FeatureToggle.CarbonFinder) === '1';

  const isCorporateTrader = () => userInfo?.user?.account_type === UserType.CORPORATE_TRADER;
  const isCorporateAdmin = () => userInfo?.user?.account_type === UserType.CORPORATE_ADMIN;
  const canUnpackGER = () => userInfo?.permissions?.includes('WEB_ACCOUNT_UNPACKING_BASKET');
  const canPackGER = () => userInfo?.permissions?.includes('WEB_ACCOUNT_PACKING_BASKET');
  const canRetireGER = () => userInfo?.permissions?.includes('WEB_ACCOUNT_RETIREMENT_BASKET');
  const getFullName = () =>
    userInfo?.profile?.cynopsisFullName ||
    `${userInfo?.user?.user_first_name ?? ''} ${userInfo?.user?.user_last_name ?? ''}`;

  // Used for member logic
  const memberClientUserTypes: string[] = [UserType.CORPORATE_CLIENT_DMA, UserType.CORPORATE_CLIENT_READ_ONLY];
  const isMember = () => userInfo?.user?.user_is_member === 1 || userInfo?.user?.user_is_parent_member === 1;
  const isMemberClient = () => memberClientUserTypes.includes(userInfo?.user?.account_type);
  const canUserTradeObo = () => !memberClientUserTypes.includes(userInfo?.user?.account_type) && isMember();

  const isAPXEnabled = () => Number(getSetting(Const.FeatureToggle.Apx)) === 1;
  const isRECEnabled = () => Number(getSetting(Const.FeatureToggle.Rec)) === 1;
  const isMarketBoardAndAuctionsEnabled = () => Number(getSetting(Const.FeatureToggle.MarketBoardAndAuctions)) === 1;

  const isReportEnabled = () => Number(getSetting(Const.FeatureToggle.Report)) === 1;
  const isCurrentBalanceReportEnabled = () => Number(getSetting(Const.FeatureToggle.CurrentBalanceReport)) === 1;
  const isAssetReportEnabled = () => Number(getSetting(Const.FeatureToggle.AssetReport)) === 1;
  const isSpotOrdersReportEnabled = () => Number(getSetting(Const.FeatureToggle.SpotOrdersReport)) === 1;
  const isTradesReportEnabled = () => Number(getSetting(Const.FeatureToggle.TradesReport)) === 1;
  const isTransfersReportEnabled = () => Number(getSetting(Const.FeatureToggle.TransfersReport)) === 1;

  const canManageAPX = () => userInfo?.permissions?.includes('WEB_ACCOUNT_APX_PROJECT:CREATE') && isAPXEnabled();
  const canTradeAPX = () => userInfo?.permissions?.includes('WEB_ACCOUNT_APX_MARKETPLACE:TRADE') && isAPXEnabled();

  const canAccessAPX = () => canManageAPX() || canTradeAPX();
  const canAccessRecs = () => userInfo?.permissions?.includes('WEB_ACCOUNT_REC') && isRECEnabled();

  const getToTpSetup = () => {
    return userInfo?.user?.user_preferred_mfa === 'SOFTWARE_TOKEN_MFA';
  };

  return {
    user,
    isLoadingUser,
    resetUser,
    fetchUser,
    fetchUserSettings: () => queryCache.invalidateQueries(userSettingsUrl),
    bankAccount,
    setBankAccount,
    TCFilename,
    rootUser,
    status: {
      isSuperAdmin: () => superAdminIds?.includes(userInfo?.user?.user_id),
      hasMfa: (type: MfaType) => String(userInfo?.user.user_mfa_type) === String(type),
      isTotpSetup: () => getToTpSetup(),
      requireAgreeCMBTerm,
      TCAccepted: () =>
        hasAccepted() || !requireAgreePlatformTerm() || bypassTCs.includes(userInfo?.user?.account_type),
      isLoadingAcceptedTerms,
      isOffmarketSeller: () => userInfo?.user?.account_type === UserType.OFFMARKET_SELLER,
      isOffmarketBuyer: () => userInfo?.user?.account_type === UserType.OFFMARKET_BUYER,
      isOffmarketUser: () =>
        userInfo?.user?.account_type === UserType.OFFMARKET_BUYER ||
        userInfo?.user?.account_type === UserType.OFFMARKET_SELLER,
      isMarketUser: () => isMarketUserTypes.includes(userInfo?.user?.account_type),
      isCorporateMonitor: () => userInfo?.user?.account_type === UserType.CORPORATE_MONITOR,
      isCorporateTrader,
      isCorporateAdmin,
      isIndividual: () => userInfo?.user?.account_type === UserType.INDIVIDUAL,
      canViewMultiAccounts: () =>
        userInfo?.user?.account_type === UserType.CORPORATE_ADMIN ||
        userInfo?.user?.account_type === UserType.CORPORATE_MONITOR,
      canViewCorpHistory: () => userInfo?.permissions?.includes('WEB_ACCOUNT_SUBACCOUNT_HISTORY'),
      canViewSpot: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRADE') && isSpotExchangeFeatureEnabled,
      canTradeSpot: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRADE:TRADE'),
      isHXRBetaTester: () => userInfo?.permissions?.includes('WEB_HXR_BETA_TESTER'),
      canViewCmbAsk: () => userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_ASK') && isMarketBoardAndAuctionsEnabled(),
      canEditCmbAsk: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_ASK:EDIT') && isMarketBoardAndAuctionsEnabled(),
      canBidCmbAsk: () => userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_BID') && isMarketBoardAndAuctionsEnabled(),
      canManageCmbBid: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_BID:MANAGE') && isMarketBoardAndAuctionsEnabled(),
      canViewAuction: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_AUCTION_VIEW') && isMarketBoardAndAuctionsEnabled(),
      canBidAuction: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_AUCTION_BID') && isMarketBoardAndAuctionsEnabled(),
      canCreateAuction: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_AUCTION_CREATE') && isMarketBoardAndAuctionsEnabled(),

      canUnpackGER,
      canPackGER,
      canRetireGER,
      canAccessGER: () =>
        (canRetireGER() || canUnpackGER() || canPackGER()) && tokenTypesGER().length > 0 && isGERFeatureEnabled,
      canDeposit: () => userInfo?.permissions?.includes('WEB_ACCOUNT_DEPOSIT') && isCashDepositFeatureEnabled,
      canWithdraw: () => userInfo?.permissions?.includes('WEB_ACCOUNT_WITHDRAW') && isCashWithdrawFeatureEnabled,
      canManageUser: () => userInfo?.permissions?.includes('WEB_ACCOUNT_ADD_SUBACCOUNT'),
      canTransferAssets: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRANSFER_FUNDS'),
      canViewOverView: () => userInfo?.permissions?.includes('WEB_ACCOUNT_OVERVIEW:VIEW'),
      canViewRFQ: () => userInfo?.permissions?.includes('WEB_ACCOUNT_RFQ:VIEW') && isCarbonFinderFeatureEnabled,
      canTradeRFQ: () => userInfo?.permissions?.includes('WEB_ACCOUNT_RFQ:EXECUTE'),
      canRetireToken: () => userInfo?.permissions?.includes('WEB_ACCOUNT_RETIREMENT') && isRetirementFeatureEnabled,
      canDeliverToken: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_PHYSICAL_DELIVERY') && isDeliveryFeatureEnabled,
      canErc20WithdrawAndDeposit: () => userInfo?.permissions?.includes('ENABLE_ERC20_WITHDRAW_AND_DEPOSIT'),
      accountType: () => userInfo?.user?.account_type?.toUpperCase(),
      isAccountActive: () => userInfo?.profile?.statusCode === 'ACTIVE',
      isAccountDisabled: () => userInfo?.profile?.statusCode === 'DISABLED',
      canViewIncomingBlockRequest: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_BLOCK_IN:VIEW') && isBlockTradesFeatureEnabled,
      canAcceptBlockRequest: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BLOCK_IN:ACCEPT'),
      canRejectBlockRequest: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BLOCK_IN:REJECT'),
      canViewOutgoingBlockRequest: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_BLOCK_OUT:VIEW') && isBlockTradesFeatureEnabled,
      canCreateBlockRequest: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_BLOCK_OUT:CREATE') && isBlockTradesFeatureEnabled,
      canViewBiofuel: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BIOFUEL_TRADE') && isBiofuelFeatureEnabled,
      canTradeBiofuel: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BIOFUEL_TRADE:TRADE'),
      canAddAccountBrokers: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BROKER:ASSIGN'),
      canRemoveAccountBrokers: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BROKER:REMOVE'),
      canManageAPX,
      canTradeAPX,
      canSeeExternalBrokerClientForBlockTrade: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:READ') &&
        allowExternalBrokerClientForBlockTrade,
      canAddExternalBrokerClientForBlockTrade: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:CREATE') &&
        allowExternalBrokerClientForBlockTrade,
      canUpdateExternalBrokerClientForBlockTrade: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:UPDATE') &&
        allowExternalBrokerClientForBlockTrade,
      canSeeExternalBrokerClientForBiofuel: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:READ') &&
        allowExternalBrokerClientForBiofuel,
      canAddExternalBrokerClientForBiofuel: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:CREATE') &&
        allowExternalBrokerClientForBiofuel,
      canUpdateExternalBrokerClientForBiofuel: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EXTERNAL_BROKER_CLIENT:UPDATE') &&
        allowExternalBrokerClientForBiofuel,

      canAccessReport: () => userInfo?.permissions?.includes('WEB_ACCOUNT_REPORTS_VIEW') && isReportEnabled(),
      canAccessCurrentBalanceReport: () => isCurrentBalanceReportEnabled(),
      canAccessAssetsReport: () => isAssetReportEnabled(),
      canAccessSpotOrdersReport: () => isSpotOrdersReportEnabled(),
      canAccessTradesReport: () => isTradesReportEnabled(),
      canAccessTransfersReport: () => isTransfersReportEnabled(),

      hasBankAccount: () => !!userInfo?.bankAccount,

      // Member flags
      isMember,
      isMemberClient,
      canUserTradeObo,
      // TODO: use specific permission for :OBO. For now using same as existing TRADE permission
      canTradeTokenObo: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRADE') && canUserTradeObo(),
      canTradeBiofuelObo: () => userInfo?.permissions?.includes('WEB_ACCOUNT_BIOFUEL_TRADE') && canUserTradeObo(),
      canCmbOfferObo: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_ASK') &&
        canUserTradeObo() &&
        isMarketBoardAndAuctionsEnabled(),
      canCmbBidObo: () =>
        userInfo?.permissions?.includes('WEB_ACCOUNT_EMB_BID') &&
        canUserTradeObo() &&
        isMarketBoardAndAuctionsEnabled(),
      canTradeRfqObo: () => userInfo?.permissions?.includes('WEB_ACCOUNT_TRADE') && canUserTradeObo(),
      canAccessAPX,
      canAccessRecs,

      // Settlement flags
      canViewSettlement: () =>
        ['WEB_ACCOUNT_SETTLE_BROKER:VIEW', 'WEB_ACCOUNT_SETTLE:VIEW'].some((permission) =>
          userInfo?.permissions?.includes(permission),
        ),
      canCreateSettlement: () =>
        ['WEB_ACCOUNT_SETTLE_BROKER:CREATE', 'WEB_ACCOUNT_SETTLE:CREATE'].some((permission) =>
          userInfo?.permissions?.includes(permission),
        ),
      canManageSettlement: () => userInfo?.permissions?.includes('WEB_ACCOUNT_SETTLE:MANAGE'),
      canCreateSettlementAsBroker: () => userInfo?.permissions?.includes('WEB_ACCOUNT_SETTLE_BROKER:CREATE'),
    },
    selector: {
      isGuest: () => !user || !userInfo,
      getUserSettings: () => userSettings,
      getUserId: () => {
        return userInfo?.user?.user_id;
      },
      getUserRootId: () => {
        return userInfo?.user?.user_parent_id ?? userInfo?.user?.user_id;
      },
      getUserRootAccount: () => {
        return userInfo?.rootUser?.accountId ?? userInfo?.account?.accountId;
      },
      isBankAccountSubmitted: () => {
        return !!bankAccount.accountIBAN;
      },
      isBankAccountApproved: () => {
        return !!bankAccount.accountIBAN && userInfo?.bankAccount?.verificationStatus === 'APPROVED';
      },
      isBankAccountRejected: () => {
        return !!bankAccount.accountIBAN && userInfo?.bankAccount?.verificationStatus === 'REJECTED';
      },
      getIBANAccount: () => {
        return bankAccount.accountIBAN.replace(/\d{4}(?=\d{4})/g, '****');
      },
      getUserProfile: () => {
        return {
          account: userInfo?.account?.accountId,
          cognito_id: userInfo?.profile?.cognitoId,
          email: userInfo?.profile?.cognitoId,
          fullName: getFullName(),
          cynopsisFullName: userInfo?.profile?.cynopsisFullName,
          first_name: userInfo?.user?.user_first_name,
          last_name: userInfo?.user?.user_last_name,
          account_type: userInfo?.user?.account_type,
          user_id: userInfo?.user?.user_id,
          parent_id: userInfo?.user?.user_parent_id,
          user_name: userInfo?.user?.user_user_name,
          is_member: userInfo?.user?.user_is_member,
          isRoot: !userInfo?.user?.user_parent_id,
          isMember: isMember(),
          isMemberClient: isMemberClient(),
        };
      },
      hasUserSignedErpa: () => userInfo?.profile?.hasSignedErpa,
      getCynopsisCustomerId: () => {
        return userInfo?.customer?.id;
      },
      getCynopsisAccountType: () => {
        return userInfo?.customer?.customerType;
      },
      getCynopsisCustomer: () => {
        return pick(userInfo?.customer?.customerType, [
          'domains',
          'isActiveCustomer',
          'natureOfBusinessRelationship',
          'onboardingMode',
          'paymentModes',
          'productServiceComplexity',
          'referenceId',
          'users',
        ]);
      },
      getFullName,
      getTradingName: () => {
        return userInfo?.profile?.tradingName;
      },
      getAccountAddress: () => {
        return userInfo?.account?.accountId;
      },
      getAuthKind: () => 'AWSCognito',
      getAuthToken: async () => {
        const cognitoUser = await Auth.currentSession();
        return cognitoUser.getAccessToken().getJwtToken();
      },
      getUserStatus: () => {
        return userInfo?.profile?.statusCode;
      },
      getKycStatus: () => {
        return rootUser?.kycStatus;
      },
      getCountryOfResidence: () => {
        return rootUser?.countryOfResidence;
      },
      getInvestorVerificationStatus: () => {
        return rootUser?.investorVerificationStatus;
      },
      getBankVerificationStatus: () => {
        return userInfo?.bankAccount?.verificationStatus ?? 'SUBMITTED';
      },
      getRoleName: () => {
        return getRoleName({
          accountType: userInfo?.user?.account_type,
          isMember: isMember(),
        });
      },
    },
    helpers: {
      memberClientUserTypes,
      memberOBOUserTypes: [UserType.CORPORATE_CLIENT_READ_ONLY],
    },
    fetchFullProfile,
    refetchTcAccepted,
  };
}

export const User = createContainer(useUser);
